var React = require('react');
var ReactDatetime = require('react-datetime');
var { useTranslation } = require('react-i18next');

// inputProps => { id, required, disabled }
const DayPickerInput = ({
  value,
  onChange,
  dateFormat = 'YYYY-MM-DD',
  dayPickerProps,
  inputProps,
  isValidDate = () => true,
}) => {
  const { t } = useTranslation();

  return (
    <ReactDatetime
      value={value}
      onChange={onChange}
      dateFormat={dateFormat}
      timeFormat={false}
      isValidDate={isValidDate}
      closeOnSelect
      onFocus={(e) => e.target.setAttribute('autocomplete', 'off')}
      {...dayPickerProps}
      inputProps={{
        className: `form-control`,
        type: 'text',
        placeholder: t('choose_date'),
        style: { width: '100%' },
        ...inputProps,
      }}
    />
  );
};

module.exports = DayPickerInput;
