import i18n from 'i18next';
import languageDetector from 'i18next-browser-languagedetector';

import { initReactI18next } from 'react-i18next';

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          height: 'Height',
          metric_india: 'Metric (India)',
          metric_israel: 'Metric (Israel)',
          metric_australia: 'Metric (Australia)',
          metric: 'Metric',
          imperial: 'Imperial',
          unit_system: 'Unit system',
          recommendations_unit: 'Recommendations unit',
          soil_water_deficit: 'Soil Water Deficit',
          login_registration_confirmation_message:
            'Please confirm your registration by clicking the confirmation link you received in your email account.',
          login_registration_after_confirmation_message:
            'After confirmation, go to',
          login_registration_after_confirmation_message_2: 'and login.',
          login_enter_your_email: 'Enter your email',
          login_enter_your_password: 'Enter your password',
          login_enter_a_valid_email: 'Enter a valid email account',
          login_enter_a_valid_confirmation_code:
            'Enter a valid confirmation code',
          login_forgot_password: 'Forgot Password?',
          login_enter_verification_code_message:
            'Enter the verification code you received in your email account.',
          login_enter_confirmation_code_message:
            'Enter the confirmation code you received in your email account.',
          login_enter_confirmation_code: 'Enter the confirmation code',
          confirmation_code: 'Confirmation Code',
          login_new_password: 'New Password',
          login_enter_a_valid_password: 'Enter a valid password',
          login_dont_have_account_message: "Don't have an account?",
          login_sign_up: 'Sign Up',
          login_enter_first_name: 'Enter your first name',
          login_enter_last_name: 'Enter your last name',
          login_enter_phone_number: 'Enter your phone number',
          login_password_limitation_message:
            'Password must have at least 8 characters with at least one lower and upper case letters.',

          account_first_name_invalid_data_message: 'Enter a valid first name',
          account_last_name_invalid_data_message: 'Enter a valid last name',
          account_phone_number_invalid_data_message:
            'Enter a valid phone number',
          account_email_invalid_data_message: 'Enter a valid email',
          account_notifications: 'Receive Notifications?',
          loading: 'Loading...',
          kc_developments: 'Development',
          kc_auto_generate: 'Auto Generate',

          kc_crop_type_invalid_data_message: 'Enter a valid crop type',
          kc_variety: 'Variety',
          kc_variety_invalid_data_message: 'Enter a valid variety',
          kc_feature: 'Feature',
          kc_feature_invalid_data_message: 'Enter a valid feature',
          kc_sow_date: 'Sow date',
          edit_field_pre_season_irrigation_start_date:
            'Pre-season irrigation start date',
          kc_sow_date_invalid_data_message: 'Enter a valid sow date',
          kc_harvest_date: 'Harvest Date',
          kc_harvest_date_invalid_data_message: 'Enter a valid harvest date',
          kc_kc_table: 'KC Table',
          kc_by_month: 'Month',
          kc_by_growth_phase: 'Growth phase',
          kc_add_development_phase: 'Add a development phase',
          kc_phase_title_placeholder: 'Enter phase name...',
          kc_first_phase_starts: 'First phase starts on',
          edit_field_choose_area_message:
            'You must choose an area for your field',
          edit_field_field_name: 'Field Name',
          edit_field_last_recommendation: 'Last Recommendation',
          edit_field_no_fields: 'No fields to display 😟',
          edit_field_field_name_invalid_data_message:
            'Enter a valid field name',
          edit_field_area_invalid_data_message:
            'Mark your field area using the draw menu on the left',
          edit: 'Edit',
          edit_field_time_zone_invalid_message: 'Enter a valid time-zone',
          edit_field_water_meter_sensors: 'Flow Meter Sensors',
          edit_field_installation_date: 'Installation Date',
          edit_field_installation_date_invalid_message:
            'Enter a valid installation date',
          edit_field_contract_duration: 'Contract End Date',
          edit_field_contract_duration_invalid_message:
            'Contract duration must be bigger than today',
          edit_field_delete_field_message:
            'Are you sure you want to delete the field?',
          cluster: 'Cluster',
          none_default: 'None - Default',
          irrigate_command: 'Please irrigate',
          get_ready_to_irrigate: 'Get ready to irrigate',
          dont_irrigate_command: 'No need to irrigate',
          stress_level_very_high: 'Stress level is very high',
          stress_level_high: 'Stress level is high',
          stress_level_getting_low: 'Stress level is getting low',
          stress_level_optimal: 'Stress level is optimal',
          stress_level_no_stress: 'No Stress',
          no_recommendation_available: 'No Recommendation Available',
          no_recommendation: 'No Recommendation',
          expiration_date: 'Expiration date',
          soil_type: 'Soil type',
          crop_type: 'Crop type',
          next_actions: 'Next Actions',
          take_the_app_to_the_field:
            'Take N-Drip Connect with you to the field',
          new: '🎉 NEW!',
          history_graph: 'History Graph',
          download_the_app: 'Download the mobile app',
          coming_soon: 'Coming Soon',
          dashboard_irrigation_amount: 'Irrigation Amount',
          dashboard_my_fields: 'My Fields',
          dashboard_delete_field: 'Delete',
          dashboard_copy_field_id: 'Copy ID',
          dashboard_edit_field: 'Edit',
          dashboard_add_field: 'Add Field',
          dashboard_new_field: 'New Field',
          dashboard_loading_data_message:
            'We are gathering data, this should take between 6 to 8 hours',
          no_planner_events: 'All clear,\nno future actions at this time',
          management_distribution_units_management:
            'Distribution Units Management',
          transmitter_admin_page: 'Transmitter Test',
          management_admins_only: 'admins only',
          management_farming_units_management: 'Farming Units Management',
          management_delete_distribution_message:
            'Are you sure you want to delete the distribution?',
          management_manage_distribution_users_title:
            'Manage {{distribution}} Users',
          management_enter_a_valid_username:
            'Enter a valid user name - ex. user@domain.com',
          management_enter_a_valid_distribution_name:
            'Enter a valid distribution name',
          management_enter_a_valid_farming_unit_name:
            'Enter a valid farming unit name',
          management_enter_a_valid_region: 'Enter a valid region',
          management_farming_unit_name: 'Farming unit name',
          management_farming_unit_salesforce_id: 'Salesforce ID',
          management_distribution_name: 'Distribution Name',
          login_page: 'Login Page',
          time_zone: 'Time Zone',
          tensiometers: 'Tensiometers',
          support_planner: 'Support Planner',
          allow_dynamic_updates: 'Allow dynamic updates',
          define_allow_dynamic_updates:
            'Recommendations will be affected by irrigation changes at any time',
          distribution_unit: 'Distribution Unit',
          farming_unit: 'Farming Unit',
          field_amount: 'Fields Amount',
          region: 'Region',
          actions: 'Actions',
          close: 'Close',
          user: 'User',
          role: 'Role',
          admin: 'Admin',
          viewer: 'Viewer',
          moderator: 'Moderator',
          account: 'Account',
          logout: 'Log out',
          first_name: 'First Name',
          last_name: 'Last Name',
          phone_number: 'Phone Number',
          email: 'Email',
          units: 'Units',
          area: 'Area',
          gdd: 'GDD',
          leaf_length: 'Leaf Length',
          et0: 'ETo',
          leached: 'Leached',
          plant: 'Plant',
          soil: 'Soil',
          temperature: 'Temperature',
          weight: 'Weight',
          pressure: 'Pressure',
          length: 'Length',
          volume: 'Volume',
          estimated_nitrogen_values: 'Estimated nitrogen values',

          temperature_celsius: 'Celsius',
          temperature_fahrenheit: 'Fahrenheit',
          weight_kg: 'Kg',
          weight_lb: 'lb',
          weight_tonne: 'Tonne',
          weight_short_ton: 'Short Ton',
          pressure_kpa: 'kPa',
          pressure_psi: 'PSI',
          pressure_cbar: 'CBar',
          pressure_mm: 'mm',
          pressure_pa: 'Pa',
          length_mm: 'mm',
          length_inch: 'Inch',
          volume_cubic_metre: 'Cubic Meter',
          volume_gallon: 'Gallon',
          volume_bushel: 'Bushel',
          volume_acre_foot: 'Acre Foot',
          volume_acre_inch: 'Acre Inch',
          volume_megaliter: 'Megaliter',

          phase: 'Phase',
          kc: 'Kc',
          kc_optimized: 'Kc optimized',
          duration: 'Duration',
          days: 'Days',

          months_january: 'January',
          months_february: 'February',
          months_march: 'March',
          months_april: 'April',
          months_may: 'May',
          months_june: 'June',
          months_july: 'July',
          months_august: 'August',
          months_september: 'September',
          months_october: 'October',
          months_november: 'November',
          months_december: 'December',
          show_archived: 'Show archived',
          choose_date: 'Choose Date',
          last_day: 'Last Day',
          last_week: 'Last Week',
          last_two_weeks: 'Last 2 Weeks',
          last_month: 'Last Month',
          last_six_months: 'Last 6 Months',
          last_year: 'Last Year',
          activity_dates: 'Activity dates',

          supporting_data: 'Supporting Data',
          water_stress_level: 'Water Stress Level',
          fertilization_gauge_title: 'Nitrogen Levels',
          map_view: 'Map View',

          serial_no: 'Serial no.',
          sensor_serial_no: "Sensor's Serial no.",
          sensor_latitude: "Sensor's Latitude",
          sensor_longitude: "Sensor's Longitude",
          water_meter_units: 'M³ per pulse',
          name: 'Name',
          latitude: 'Latitude',
          longitude: 'Longitude',

          create: 'Create',
          update: 'Update',
          field: 'Field',
          cancel: 'Cancel',
          delete: 'Delete',
          ok: 'OK',
          confirmation: 'Confirmation',
          confirm: 'Confirm',
          yes: 'Yes',
          no: 'No',
          home: 'Home',
          change: 'Change',
          forgot: 'Forgot',
          password: 'Password',
          login: 'Login',
          prefix: 'Prefix',
          register: 'Register',
          settings: 'Settings',
          based_on: 'Based on',
          amount: 'Amount',
          date_and_time: 'Date & Time',
          report: 'Report',
          hours: 'Hours',
          report_irrigation: 'Report Irrigation',
          report_nitrogen: 'Report Nitrogen',
          irrigation_reported_successfully: 'Irrigation Reported Successfully',
          fertilization_reported_successfully:
            'Fertilization Reported Successfully',
          rain_reported_successfully: 'Rain Reported Successfully',
          wtr_reported_successfully: 'Water Treatment Reported Successfully',
          is_manure: 'Is Manure?',
          irrigation: 'Irrigation',
          select_sensors: 'Select Sensors',
          select_all: 'Select All',
          manure: 'Manure',
          nitrogen: 'Nitrogen',
          fertilization_history: 'Fertilization History',
          water_pressure_history: 'Water Pressure History',
          designed_pressure: 'Designed pressure',
          support_ndvi: 'Support NDVI?',
          support_coup_model: 'Support Coup Model?',
          n_in_organic_matter: 'N in Organic Matter',
          initial_concentrations: 'Initial Concentrations',
          should_automate_irrigation: 'Automate Irrigation',
          location: 'Location',
          i_agree_to: 'I agree to ',
          terms_and_conditions: 'terms and conditions',
          language: 'Language',
          last_update: 'Last update: ',
          hours_ago: '{0}h ago',
          hour_ago: '{0}h ago',
          day_ago: '{0}d ago',
          days_ago: '{0}d ago',
          minute_ago: '{0}min ago',
          minutes_ago: '{0}min ago',
          today: 'today',
          yesterday: 'yesterday',
          enter_transmitter_id: 'Enter the transmitter ID',
          transmitter_id: 'Transmitter ID',
          sim_activation:
            'Sim is being activated, please wait 10 minutes before testing again',
          last_seen: 'Last Seen',
          recent_analog_values: 'Recent Analog Values',
          recent_connectivity_values: 'Recent Connectivity Values',
          recent_battery_values: 'Recent Battery Values',
          recent_c1_values: 'Recent C1 Values',
          recent_errors_values: 'Recent Error Values',
          installation_date: 'Installation Date',
          modem_version: 'Modem Version',
          modem_hardware: 'Modem Hardware',
          iccid: 'ICCID',
          sensor_batch: 'Sensor Batch',
          transmission_interval: 'Transmission Interval',
          sample_interval: 'Sample Interval',
          micro_version: 'Micro Version',
          sim_last_event: 'SIM last event',
          date: 'Date',
          value: 'Value',
          test: 'Test',
          Sand: 'Sand',
          'Loamy Sand': 'Loamy Sand',
          'Sandy Loam': 'Sandy Loam',
          Loam: 'Loam',
          Silt: 'Silt',
          'Silty Loam': 'Silty Loam',
          'Sandy Clay Loam': 'Sandy Clay Loam',
          'Clay Loam': 'Clay Loam',
          'Silty Clay Loam': 'Silty Clay Loam',
          'Sandy Clay': 'Sandy Clay',
          'Silty Clay': 'Silty Clay',
          Clay: 'Clay',
          'Mix of Peat types': 'Mix of Peat types',
          'Coconut fiber': 'Coconut fiber',
          'Mixed Tuff': 'Mixed Tuff',
          water_potential_graph: 'Water potential graph',
          Cabbage: 'Cabbage',
          Carrot: 'Carrot',
          Cauliflower: 'Cauliflower',
          Celery: 'Celery',
          Lettuce: 'Lettuce',
          Onion: 'Onion',
          Spinach: 'Spinach',
          Radish: 'Radish',
          Eggplant: 'Eggplant',
          'Sweet pepper': 'Sweet pepper',
          Tomato: 'Tomato',
          Cantaloupe: 'Cantaloupe',
          Cucumber: 'Cucumber',
          'Pumpkin (Winter Squash)': 'Pumpkin (Winter Squash)',
          Squash: 'Squash',
          Zucchini: 'Zucchini',
          'Sweet Melon': 'Sweet Melon',
          Beet: 'Beet',
          Cassava: 'Cassava',
          Potato: 'Potato',
          'Sweet potato': 'Sweet potato',
          'Sugar Beet': 'Sugar Beet',
          Bean: 'Bean',
          'Faba bean, broad bean': 'Faba bean, broad bean',
          'Green Gram': 'Green Gram',
          Cowpea: 'Cowpea',
          'Peanut (Groundnut)': 'Peanut (Groundnut)',
          Lentil: 'Lentil',
          Pea: 'Pea',
          Soybean: 'Soybean',
          Artichoke: 'Artichoke',
          Asparagus: 'Asparagus',
          Cotton: 'Cotton',
          Flax: 'Flax',
          'Castorbean (Ricinus)': 'Castorbean (Ricinus)',
          Safflower: 'Safflower',
          Sesame: 'Sesame',
          Sunflower: 'Sunflower',
          Barley: 'Barley',
          Oat: 'Oat',
          Wheat: 'Wheat',
          Maize: 'Maize',
          Millet: 'Millet',
          Sorghum: 'Sorghum',
          Rice: 'Rice',
          Alfalfa: 'Alfalfa',
          Bermuda: 'Bermuda',
          Sudan: 'Sudan',
          Sugarcane: 'Sugarcane',
          Banana: 'Banana',
          Pineapple: 'Pineapple',
          Grape: 'Grape',
          Hop: 'Hop',
          Citrus: 'Citrus',
          Olive: 'Olive',
          Pistachio: 'Pistachio',
          Walnut: 'Walnut',
          'Cattails, Bulrush': 'Cattails, Bulrush',
          Mango: 'Mango',
          Grapefruit: 'Grapefruit',
          Garden: 'Garden',
          Grass: 'Grass',
          'Genus Citrus': 'Genus Citrus',
          Avocado: 'Avocado',
          Coffee: 'Coffee',
          Corn: 'Corn',
          Lychee: 'Lychee',
          'Peach, Nectarine': 'Peach, Nectarine',
          Watermelon: 'Watermelon',
          'Date Palm': 'Date Palm',
          Pomegranate: 'Pomegranate',
          Cannabis: 'Cannabis',
          Pepper: 'Pepper',
          Blackberry: 'Blackberry',
          Strawberry: 'Strawberry',
          Pecan: 'Pecan',
          start: 'Start',
          end: 'End',
          discharge: 'Discharge',
          'recent wfr samples': 'Recent Flow Meter Values',
          day_sunday: 'Sunday',
          day_monday: 'Monday',
          day_tuesday: 'Tuesday',
          day_wednesday: 'Wednesday',
          day_thursday: 'Thursday',
          day_friday: 'Friday',
          day_saturday: 'Saturday',
          planning: 'Planning',
          monitoring: 'Monitoring',
          planning_days: 'Days',
          planning_weeks: 'Weeks',
          planning_publish: 'Publish',
          planning_planned: 'planned',
          planning_used: 'used',
          tomorrow: 'tomorrow',
          later: 'later',
          event: 'Event',
          events: 'events',
          fertilization: 'Fertilization',
          hcl: 'HCl',
          hpo: 'HPO',
          note: 'Footnote',
          planning_note_placeholder: 'Write your massage here',
          'out of': 'out of',
          'accumulated this week': 'accumulated this week',
          'accumulated this season': 'accumulated this season',
          sensors: 'Sensors',
          valves: 'Valves',
          type: 'Type',
          'coming up': 'coming up',
          notify: 'notify',
          irrigation_system: 'Irrigation System',
          irrigation_type: 'Irrigation Type',
          irrigation_rate: 'Irrigation Rate',
          irrigation_time_frames: 'Irrigation Time Frames',
          define_time_frames: 'Define available time frames for irrigation',
          start_day: 'Start day',
          start_hour: 'Start hour',
          end_hour: 'End hour',
          time_frame_conflict:
            'Selected Time Frame conflicts with existing Time Frame',
          select_different_hour: 'Please select Different Hour',
          select_different_day: 'Please select Different Day',
          no_time_frames: 'There are no irrigation time frames constraints',
          for_this_field: 'for this field at the moment',
          locate_coordinates: 'Locate coordinates',
          ignore_sensor_during_calculations: 'Ignore during calculations',
          ignore_sensor_during_calculations_and_hide:
            'Ignore during calculations and hide',
          consider_as_flow_meter: 'Consider as flow meter',
          alerts: 'alerts',
          stop_data_collection: 'Stop data collection',
          accumulated_water_input: 'Accumulated water input',
          precipitation: 'Precipitation',
          water_potential: 'Water potential',
          petiole: 'Petiole',
          minimum: 'Min',
          maximum: 'Max',
          max_bigger_than_min: 'Max value need to be bigger than the Min value',
          min_smaller_than_max:
            'Min value need to be smaller than the Max value',
          updated: 'Updated',
          not_yet_set: 'Not yet set',
          missing_values: 'Values are missing',
          start_date: 'Start date',
          end_date: 'End date',
          fertigation_concentrations: 'Fertigation concentrations',
          no_fertigation_concentrations_message:
            'There are no fertigation concntrations',
          general_coup_settings: 'General Coup Model settings',
          days_from_sowing: 'Days from Sowing',
          Field_details_days_in_season: 'Days in season',
          field_size: 'Field size',
          sensor_type: 'Sensor type',
          sensor_sub_type: 'Sensor Sub type',
          duplicate_field: 'Duplicate Field',
          duplicate: 'Duplicate',
          dashboard_move_to_archive: 'Archive',
          dashboard_unarchived: 'Unarchived',
          alphabetic: 'Alphabetic',
          sort_by: 'Sort by',
          Almond: 'Almond',
          Apple: 'Apple',
          Arugula: 'Arugula',
          Blueberry: 'Blueberry',
          Broccoli: 'Broccoli',
          'Faba Bean, Broad Bean': 'Faba Bean, Broad Bean',
          Fig: 'Fig',
          Garlic: 'Garlic',
          Guayule: 'Guayule',
          Jojoba: 'Jojoba',
          Kiwi: 'Kiwi',
          Loquat: 'Loquat',
          Mandarin: 'Mandarin',
          Moringa: 'Moringa',
          Nectarine: 'Nectarine',
          Orange: 'Orange',
          Peach: 'Peach',
          Peony: 'Peony',
          Raspberry: 'Raspberry',
          'Sweet Cherry': 'Sweet Cherry',
          'Sweet Pepper': 'Sweet Pepper',
          'Sweet Potato': 'Sweet Potato',
          '1st Cutting Cycle': '1st Cutting Cycle',
          'Other Cutting Cycles': 'Other Cutting Cycles',
          'Clay (Red Oxisol) (Pivot Irrigation)':
            'Clay (Red Oxisol) (Pivot Irrigation)',
          'Clay (Red Oxisol)': 'Clay (Red Oxisol)',
          'Coconut Fiber': 'Coconut Fiber',
          'Silt Loam': 'Silt Loam',
          'Clay (Ramot Gonen Pardes)': 'Clay (Ramot Gonen Pardes)',
          'Clay Loam (6mm)': 'Clay Loam (6mm)',
          'Sandy Clay (Shefayim)': 'Sandy Clay (Shefayim)',
          'Clay 1000mm': 'Clay 1000mm',
          'Clay Loam Et': 'Clay Loam Et',
          'Loam (Agroterra)': 'Loam (Agroterra)',
          'Clay (Ramot Gonen)': 'Clay (Ramot Gonen)',
          'Clay (Red Oxisol) Au': 'Clay (Red Oxisol) Au',
          'Clay Loam (Peanuts)': 'Clay Loam (Peanuts)',
          'Clay (Adashim)': 'Clay (Adashim)',
          'Clay Loam Au': 'Clay Loam Au',
          'Clay (Pivot Irrigation)': 'Clay (Pivot Irrigation)',
          'Clay Au': 'Clay Au',
          'Clay (Cotton)': 'Clay (Cotton)',
          'Loam (6mm)': 'Loam (6mm)',
          'Clay Loam (Daliot)': 'Clay Loam (Daliot)',
          'Sand Ornamental': 'Sand Ornamental',
          'Clay Loam (Pivot Irrigation)': 'Clay Loam (Pivot Irrigation)',
          'Mix Of Peat Types': 'Mix Of Peat Types',
          'Clay Loam (Ak-Chin)': 'Clay Loam (Ak-Chin)',
          Planner: 'Planner',
          'Planned irrigation': 'Planned irrigation',
          Irrigated: 'Irrigated',
          'Nitrogen graph': 'Nitrogen graph',
          search_by_field: 'Search By Field',
          free_search: 'Free Search',
          transmitters: 'Transmitters',
          distribution: 'Distribution',
          week: 'Week',
          season: 'Season',
          reported: 'Reported',
          events_list: 'Events List',
          no_reported: 'No Reported Events',
          transmitter_name: 'Transmitter Name',
          details: 'details',
          'discharge over time': 'Discharge over time',
          select_water_input: 'Select Water Input',
          etc: 'ETc',
          next_day: 'Next day',
          crop_management: 'Crop Management',
          insights: 'Insights',
          number_of_fields: 'Number of fields',
          total_area: 'Total area',
          total_water_usage: 'Total water usage',
          total_fertilizers_usage: 'Total fertilizers usage',
          total_nitrogen_leached: 'Total nitrogen leached',
          crop_types: 'Crop types',
          report_event: 'Report event',
          save: 'Save',
          measurement: 'measurement',
          rain: 'rain',
          starting_time: 'starting time',
          'please select': 'Please Select',
          create_planner_item: 'add new planner item',
          update_planner_item: 'update planner item',
          containing: 'containing',
          no_cluster: 'No Cluster',
          offset: 'Offset',
          notes: 'Notes',
          week_total: 'Week total',
          season_total: 'Season total',
          selected_field_only: 'Selected field only',
          farming_type: 'Farming type',
          plant_height: 'Plant Height',
          fert_tank_height: 'Fert tank level',
          Fert_tank_volume: 'Fert tank volume',
          fert_tank: 'Fert tank',
          calibration_data: 'Calibration data',
          recommendations_tooltip:
            'Recommendations are insights that help optimize yields and improve decision-making',
          allow_notifications: 'Allow Notifications',
          notifications_channels: 'In which channels you will be notified',
          event_type: 'Event Type',
          login_enter_your_email_forgot_password:
            'Enter your email and we will send you a code for resetting your password:',
          push: 'Push',
          recommendations: 'Recommendations',
          field_notifications:
            'Which notifications you will receive for each field',
          none: 'None',
          notifications: 'Notifications',
          notifications_phase:
            'Control notification types and channels by which you will be notified, per each field',
          alerts_tooltip:
            'Alerts flag adverse events that require immediate action, to protect your crops',
          all_fields: 'All fields',
          current_field: 'Current field',
          alerts_message_continuous_irrigation_notification_center_title:
            'Continuous irrigation alert - In fieldX',
          alerts_message_continuous_irrigation_notification_center_body:
            "We've detected continuous irrigation for over 2 days in the field. To protect your crops and resources, we strongly advise inspecting the field for over-irrigation.",
          alerts_message_sensor_extreme_dry_conditions_notification_center_title:
            'Extreme dry conditions detected in the field - Data received from sensorX',
          alerts_message_sensor_extreme_dry_conditions_notification_center_body:
            'Data from sensorX indicates that conditions in the field are extremely dry. To ensure accurate data and protect your crops, we recommend inspecting the irrigation plan, and the irrigation system around the sensor',
          alerts_message_long_dry_period_notification_center_title:
            'Long period without irrigation - Irrigation of fieldX advised',
          alerts_message_long_dry_period_notification_center_body:
            'fieldX has not received water for at least 10 days, contributing to the water stress level We advise irrigating the field to safeguard your crops, according to your N-Drip recommendations.',
          alerts_message_drying_trend_notification_center_title:
            'Drying trend detected in your field',
          alerts_message_wetting_trend_notification_center_title:
            'Wetting trend detected in your field',
          alerts_message_drying_trend_notification_center_body:
            'We’ve noticed a decreasing trend of the soil water potential in fieldX, below the recommended level. To optimize crop health, please review your current irrigation practice and adjust according to N-Drip recommendations.',
          alerts_message_wetting_trend_notification_center_body:
            'We’ve noticed an increasing trend of the soil water potential in fieldX, above the recommended level. To optimize crop health, please review your current irrigation practice and adjust according to N-Drip recommendations.',
          alerts_message_excessive_heat_notification_center_title:
            'High air-temperature',
          alerts_message_excessive_heat_notification_center_body:
            'High temperatures for your crop are forecast for the fieldX in the coming days.\nTo protect your crops, please review and implement our updated irrigation recommendations.',
          alerts_message_extreme_cold_notification_center_title:
            'Low air-temperature',
          alerts_message_extreme_cold_notification_center_body:
            'Low temperatures for your crop are forecast for the fieldX in the coming days.\nTo protect your crops, please review and implement our updated irrigation recommendations.\n',
          alerts_message_dryland_long_dry_period_notification_center_title:
            'Long dry period detected',
          alerts_message_dryland_long_dry_period_notification_center_body:
            'fieldX has not received water for at least 10 days, contributing to the water stress level.',
          alerts_message_dryland_sensor_exterme_dry_notification_center_title:
            'Extreme dry conditions detected in the field - Data received from sensorX',
          alerts_message_dryland_sensor_exterme_dry_notification_center_body:
            'Data from sensorX indicates that conditions in the field are extremely dry',
          alerts_message_dryland_drying_trend_notification_center_title:
            'Drying trend detected in your field',
          alerts_message_dryland_drying_trend_notification_center_body:
            'We’ve noticed a decreasing trend of the soil water potential in fieldX, contributing to the water stress level',
          alerts_message_low_tank_fert_notification_center_title:
            'Low fertilizer level detected in fieldX',
          alerts_message_low_tank_fert_notification_center_body:
            'We have detected that the fertilizer level in the tank at fieldX has reached the minimum threshold of 10% of the tank’s operational height. Please arrange to refill the tank to ensure continued fertilization of the crops.',
          alerts_message_dryland_wetting_trend_notification_center_title:
            'Wetting trend detected in your field',
          alerts_message_dryland_wetting_trend_notification_center_body:
            'We’ve noticed an increasing trend of the soil water potential in fieldX',
          alerts_message_dryland_excessive_heat_notification_center_title:
            'Heat wave alert',
          alerts_message_dryland_excessive_heat_notification_center_body:
            'A significant heat wave is forecast for the fieldX area in the coming days',
          alerts_message_dryland_extreme_cold_notification_center_title:
            'Cold wave alert',
          alerts_message_dryland_extreme_cold_notification_center_body:
            'A significant cold wave is forecast for the fieldX area in the coming days.',
          alerts_message_dryland_ndvi_notification_center_title:
            'Decreasing vegetative activity',
          alerts_message_dryland_ndvi_notification_center_body:
            'Our recent analysis of NDVI image history has revealed a notable decrease in vegetation growth within fieldX. To optimize crop health, please review your current irrigation practice.',
          no_notifications: 'There are no notifications at the moment',
          notifications_welcome_message_title:
            'Welcome to the new notifications panel',
          notifications_welcome_message_body:
            'Stay on top of current and expected important events in your fields trough desktop and mobile',
          planner_days_shorts_sun: 'Sun',
          planner_days_shorts_mon: 'Mon ',
          planner_days_shorts_tue: 'Tue',
          planner_days_shorts_wed: 'Wed',
          planner_days_shorts_thu: 'Thu',
          planner_days_shorts_fri: 'Fri',
          planner_days_shorts_sat: 'Sat',
          shorts_months_january: 'Jan',
          shorts_months_february: 'Feb',
          shorts_months_march: 'Mar',
          shorts_months_april: 'Apr',
          shorts_months_may: 'May',
          shorts_months_june: 'Jun',
          shorts_months_july: 'Jul',
          shorts_months_august: 'Aug',
          shorts_months_september: 'Sep',
          shorts_months_october: 'Oct',
          shorts_months_november: 'Nov',
          shorts_months_december: 'Dec',
          dont_show_again: 'Don’t show again',
          notifications_settings: 'Notifications settings',
          read_more: 'Read more',
          show_less: 'Show less',
          distribution_menu_select: 'Select Distribution',
          farming_unit_menu_select: 'Select Farming Unit',
          field_menu_select: 'Select Field',
          recent_field: 'Recent field',
          discharge_test: 'Discharge Test',
          planner_season_start: 'Season start',
          planner_season_end: 'Season end',
          planner_growth_phase_initial: 'Initial',
          planner_growth_phase_development: 'Development',
          planner_growth_phase_middle: 'Middle',
          planner_growth_phase_late: 'Late',
          planner_export_button: 'Export',
          planner_add_event_button: 'Add event',
          planner_water_treatment: 'Water treatment',
          planner_add_button: 'Add',
          planner_start_time: 'Start time',
          country: 'Country',
          monday_id: 'Monday ID',
          delete_item_title: 'The item will be deleted',
          delete_item_text:
            'This action can not be reverted and the data will be lost',
          average: 'Average',
          seconds: 'sec',
          event_delete_successfully: 'The event was deleted successfully',
          reported_successfully: 'The test has been reported successfully',
          report_unsuccessfully:
            'The event cannot be reported at this time. Please try again later',
          event_delete_unsuccessfully:
            'The event cannot be deleted at this moment, please try again later',
          Insights_title_county: 'County',
          Insights_group_by: 'Group by',
          edit_field_measured_irrigation_rate: 'Measured irrigation rate',
          edit_field_custom_irrigation_rate: 'Custom irrigation rate',
          edit_field_representative_input_checkbox:
            'Use measured irrigation rate once available. This will impact the Planner',
          edit_field_tank_type: 'Tank type',
          wtr_checkbox: 'Include water treatment',
          kc_phases_stress_checkbox: 'Apply stress',
          kc_phases_stress_tooltip:
            'Start a planned period of crop water stress according to the following percentage reduction in ETc',
          edit_field_irrigation_timeframe_source_soil: 'Soil type limitation',
          edit_field_irrigation_timeframe_tooltip: 'Original limitation:',
          edit_field_irrigation_timeframe_source_irrigation_rate:
            'Irrigation rate',
          edit_field_irrigation_timeframe_source_user: 'Defined by user',
          source: 'Source',
          discharge_result_exceeding_hint:
            'Test result deviates from the discharge trend',
          Timeframe_end_of_season: 'End of season',
          Farming_unit_entity_basic_info: 'Basic info',
          Farming_unit_entity_add_new_button: 'Add new',
          WT_protocol_edit_field_water_properties_section_title:
            'Water properties',
          WT_protocol_edit_field_water_source: 'Water source',
          WT_protocol_edit_field_water_conveyance: 'Water conveyance ',
          WT_protocol_edit_field_water_analysis_section_title: 'Water analysis',
          WT_protocol_edit_field_water_analysis_section_subtitle:
            'See recommended ranges in each cell',
          WT_protocol_edit_field_water_analysis_table_materials: 'Materials',
          WT_protocol_edit_field_water_analysis_table_score: 'Score',
          WT_protocol_edit_field_water_analysis_table_low: 'Low',
          WT_protocol_edit_field_water_analysis_table_medium: 'Medium',
          WT_protocol_edit_field_water_analysis_table_high: 'High',
          WT_protocol_edit_field_water_analysis_table_organic: 'Organic',
          WT_protocol_edit_field_water_analysis_table_calc: 'Calc',
          WT_protocol_edit_field_water_analysis_table_fe: 'Fe',
          WT_protocol_edit_field_material_concentrations:
            'Material concentrations',
          WT_protocol_edit_field_acid_titration: 'Acid titration',
          WT_protocol_edit_field_HPO_concentrations: 'HPO concentrations',
          Farming_unit_entity_tooltip:
            'Disabled elements can be edited through the farming unit',
          WT_timing_recommendation_after_irrigation_starts:
            'After 30 min of irrigation',
          WT_timing_recommendation_last_hour: 'Last hour of irrigation',
          Fert_sensor_fertlization_type: 'Fertilizer type',
          fertilizer_meter_id: 'Fertilizer meter ID',
          add_fertilizer: 'Add fertilizer',
          Farming_unit_entity_all: 'All',
          basic_info: 'Basic Info',
          KC_phases_title: 'Growth phase',
          KC_phases_title_pre_season: 'Pre season',
          KC_phases_title_in_between_seasons: 'Between seasons',
          KC_phases_phase_tooltip_start: 'Start',
          KC_phases_phase_tooltip_end: 'End',
          KC_phases_button_save: 'Save',
          KC_phases_button_reset: 'Reset',
          KC_phases_stress_inidication: 'Controlled deficit irrigation',
          KC_phases_button_edit: 'Edit',
          KC_phases_fast_flow: 'Set current phase',
          KC_phases_now_indication: 'Now',
          KC_phases_estimated_short: 'Est.',
          KC_phases_season_ended: 'Season ended',
          KC_phases_season_end: 'Season ends in',
          KC_phases_estimated_season_end: 'Estimated season end',
          KC_phases_edit_mode_default_title:
            'Click on a phase to adjust its duration',
          soil_temperature_values: 'Soil temp avg, max, last',
          installation_depth: 'Depth',
          kc_phases_error_message: 'Value conflicts with another value',
        },
      },
      pt: {
        translations: {
          edit_field_pre_season_irrigation_start_date:
            'Data de início da irrigação pré-temporada',
          login_registration_confirmation_message:
            'Por favor, confirme sua inscrição clicando no link de confirmação enviado para o seu email.',
          login_registration_after_confirmation_message:
            'Após a confirmação, vá até',
          login_registration_after_confirmation_message_2: 'e Entrar.',
          login_enter_your_email: 'Insira seu endereço de email',
          login_enter_your_password: 'Insira sua senha',
          login_enter_a_valid_email: 'Insira uma conta de email válida',
          login_enter_a_valid_confirmation_code:
            'Insira um código de confirmação válido',
          login_forgot_password: 'Esqueceu sua senha?',
          login_enter_verification_code_message:
            'Insira o código de verificação recebido no seu email.',
          login_enter_confirmation_code_message:
            'Insira o código de confirmação recebido no seu email.',
          login_enter_confirmation_code: 'Insira o código de confirmação',
          confirmation_code: 'Código de confirmação',
          login_new_password: 'Nova senha',
          login_enter_a_valid_password: 'Insira uma senha válida',
          login_dont_have_account_message: 'Não possui uma conta?',
          login_sign_up: 'Increver-se',
          login_enter_first_name: 'Insira seu primeiro nome',
          login_enter_last_name: 'Insira seu sobrenome',
          login_enter_phone_number: 'Insira seu número de telefone',
          login_password_limitation_message:
            'Sua senha deverá possuir no mínimo 8 caracteres, sendo pelo menos uma letra maiúscula e uma letra minúscula.',
          account_first_name_invalid_data_message:
            'Insira um primeiro nome válido',
          account_last_name_invalid_data_message: 'Insira um sobrenome válido',
          account_phone_number_invalid_data_message:
            'Insira um número de telefone válido',
          account_email_invalid_data_message:
            'Insira um endereço de email válido',
          account_notifications: 'Deseja receber notificações de irrigação?',
          loading: 'Carregando...',
          kc_developments: 'Desenvolvimento',
          kc_auto_generate: 'Gerar automaticamente',
          kc_crop_type_invalid_data_message: 'Insira uma cultura válida',
          kc_variety: 'Variedade',
          kc_variety_invalid_data_message: 'Insira uma variedade válida',
          kc_feature: 'Característica ',
          kc_feature_invalid_data_message: 'Insira uma característica válida',
          kc_sow_date: 'Data de plantio',
          kc_sow_date_invalid_data_message: 'Insira uma data de plantio válida',
          kc_kc_table: 'Tabela de Kc',
          kc_by_month: 'Mensal',
          kc_by_growth_phase: 'Fases de crescimento',
          kc_add_development_phase: 'Adicionar uma fase de desenvolvimento',
          kc_phase_title_placeholder: 'Inserir título da fase de crescimento',
          kc_first_phase_starts: 'Primeira fase inicia-se em',
          edit_field_choose_area_message: 'Demarque a área da lavoura',
          edit_field_field_name: 'Nome da plantação',
          edit_field_last_recommendation: 'Última recomendação',
          edit_field_no_fields: 'Nenhuma plantação a ser exibida',
          edit_field_field_name_invalid_data_message:
            'Insira um nome de plantação válido',
          edit_field_area_invalid_data_message:
            'Demarque a área de sua lavoura utilizando o menu à esquerda',
          edit_field_time_zone_invalid_message: 'Insira um fuso horário válido',
          edit_field_water_meter_sensors:
            'Sensores de Medição de Água de Irrigação',
          edit_field_installation_date: 'Data de instalação',
          edit_field_installation_date_invalid_message:
            'Insira uma data de instalação válida',
          edit_field_contract_duration: 'Dia de término do contrato',
          edit_field_contract_duration_invalid_message:
            'Duração do contrato deve ser maor que hoje',
          edit_field_delete_field_message:
            'Tem certeza que deseja excluir a plantação?',
          irrigate_command: 'Por favor, irrigue',
          get_ready_to_irrigate: 'Prepare-se para a irrigação',
          dont_irrigate_command: 'Não há necessidade de irrigação',
          stress_level_very_high: 'Nível de estresse é muito alto',
          stress_level_high: 'Nível de estresse é alto',
          stress_level_getting_low: 'Nível de estresse está reduzindo',
          stress_level_optimal: 'Nível de estresse é ótimo',
          no_recommendation_available: 'Recomendação não disponível',
          expiration_date: 'Data de vencimento do contrato',
          soil_type: 'Solo',
          crop_type: 'Cultura',
          based_on: 'baseado em',
          test: 'Teste',
          dashboard_irrigation_amount: 'Lâmina de irrigação',
          dashboard_my_fields: 'Minhas plantações',
          dashboard_delete_field: 'Excluir',
          dashboard_copy_field_id: 'Copiar ID',
          dashboard_edit_field: 'Editar',
          dashboard_add_field: 'Adicionar plantação',
          dashboard_new_field: 'Nova plantação',
          dashboard_loading_data_message:
            'Estamos coletando dados pelas próximas 6-8 horas',
          management_distribution_units_management:
            'Gestão das Unidades de Distribuição',
          transmitter_admin_page: 'Avaliação dos transmissores',
          management_admins_only: 'Apenas administradores',
          management_farming_units_management: 'Gestão das Unidades Agrícolas',
          management_delete_distribution_message:
            'Tem certeza que deseja excluir a unidade de distribuição?',
          management_manage_distribution_users_title:
            'Gestão {{Distribuição}} Usuários',
          management_enter_a_valid_username:
            'Insira um nome de usuário válido - ex. user@domain.com',
          management_enter_a_valid_distribution_name:
            'Insira um nome de distribuição válido',
          management_enter_a_valid_farming_unit_name:
            'Insira um nome de unidade agrícola válido',
          management_enter_a_valid_region: 'Insira uma região válida',
          management_farming_unit_name: 'Nome da Unidade Agrícola',
          management_farming_unit_salesforce_id: 'Salesforce ID',
          management_distribution_name: 'Nome da Unidade de Distribuição',
          time_zone: 'Fuso horário',
          tensiometers: 'Tensiômetros',
          distribution_unit: 'Unidade de Distribuição',
          farming_unit: 'Unidade Agrícola',
          field_amount: 'Número de plantações',
          region: 'Região',
          actions: 'Ações',
          close: 'Fechar',
          user: 'Usuário',
          role: 'Função',
          admin: 'Administrador',
          viewer: 'Operador',
          account: 'Conta',
          logout: 'Sair',
          first_name: 'Primeiro nome',
          last_name: 'Sobrenome',
          phone_number: 'Número de telefone',
          email: 'Email',
          units: 'Unidades',
          area: 'Área',
          gdd: 'GDD',
          kc: 'Kc',
          kc_optimized: 'Kc otimizado',
          et0: 'ETo',
          leached: 'Lixiviado',
          plant: 'Planta',
          soil: 'Solo',
          temperature: 'Temperatura',
          weight: 'Massa',
          pressure: 'Pressão',
          length: 'Comprimento',
          volume: 'Volume',
          estimated_nitrogen_values: 'Valores estimados de nitrogênio',
          temperature_celsius: 'Celsius',
          temperature_fahrenheit: 'Fahrenheit',
          weight_kg: 'Kg',
          weight_lb: 'lb',
          weight_tonne: 'Tonelada',
          weight_short_ton: 'Tonelada curta',
          pressure_kpa: 'kPa',
          pressure_psi: 'PSI',
          pressure_cbar: 'CBar',
          pressure_mm: 'mm',
          pressure_pa: 'Pa',
          length_mm: 'mm',
          length_inch: 'Polegada',
          volume_cubic_metre: 'Metro cúbico',
          volume_gallon: 'Galão',
          volume_bushel: 'Bushel',
          volume_acre_foot: 'Acre-pé',
          volume_acre_inch: 'Acre-polegada',
          volume_megaliter: 'Megalitro',
          phase: 'Fase',
          duration: 'Duração',
          days: 'Dias',
          months_january: 'Janeiro',
          months_february: 'Fevereiro',
          months_march: 'Março',
          months_april: 'Abril',
          months_may: 'Maio',
          months_june: 'Junho',
          months_july: 'Julho',
          months_august: 'Agosto',
          months_september: 'Setembro',
          months_october: 'Outubro',
          months_november: 'Novembro',
          months_december: 'Dezembro',
          choose_date: 'Selecione a data',
          last_day: 'Último dia',
          last_week: 'Última semana',
          last_two_weeks: 'Últimas 2 semanas',
          last_month: 'Último mês',
          last_six_months: 'Últimos 6 meses',
          supporting_data: 'Dados de apoio',
          water_stress_level: 'Nível de estresse hídrico',
          fertilization_gauge_title: 'Nível de nitrogênio',
          map_view: 'Visualização do mapa',
          serial_no: 'Número de série',
          sensor_serial_no: 'Número de série do sensor',
          sensor_latitude: 'Latitude do sensor',
          sensor_longitude: 'Longitude do sensor',
          water_meter_units: 'M³ por pulso',
          name: 'Nome',
          latitude: 'Latitude',
          longitude: 'Longitude',
          create: 'Criar',
          update: 'Atualizar',
          field: 'Plantação',
          cancel: 'Cancelar',
          delete: 'Deletar',
          ok: 'OK',
          confirmation: 'Confirmação',
          confirm: 'Confirmar',
          yes: 'Sim',
          no: 'Não',
          home: 'Início',
          change: 'Modificar',
          forgot: 'Esqueceu',
          password: 'Senha',
          login: 'Entrar',
          prefix: 'Prefixo',
          register: 'Registrar',
          settings: 'Configurações',
          amount: 'Quantidade',
          date_and_time: 'Data & Hora',
          report: 'Inserir',
          hours: 'Horas',
          report_irrigation: 'Inserir Irrigação',
          report_nitrogen: 'Inserir Nitrogênio',
          irrigation_reported_successfully: 'Irrigação inserida com sucesso',
          is_manure: 'É esterco?',
          irrigation: 'Irrigação',
          select_sensors: 'Sensores selecionados',
          select_all: 'Selecionar tudo',
          manure: 'Esterco',
          nitrogen: 'Nitrogênio',
          fertilization_history: 'Histórico de fertilização',
          water_pressure_history: 'Histórico de Potencial Hídrico',
          transmitter_id: 'Identificação do transmissor',
          support_ndvi: 'Suportar NDVI?',
          i_agree_to: 'Eu concordo em',
          terms_and_conditions: 'Termos e condições',
          language: 'Idioma',
          last_update: 'Última atualização: ',
          hours_ago: 'há {0} horas',
          hour_ago: 'há {0} hora',
          day_ago: 'há {0} dia',
          days_ago: 'há {0} dias',
          minute_ago: 'há {0} minuto',
          minutes_ago: 'há {0} minutos',
          login_page: 'Página de Entrada',
          Sand: 'Arenoso',
          'Loamy Sand': 'Areia Franca',
          'Sandy Loam': 'Franco arenoso',
          Loam: 'Franco',
          Silt: 'Siltoso',
          'Silty Loam': 'Franco siltoso',
          'Sandy Clay Loam': 'Franco argilo arenoso',
          'Clay Loam': 'Franco argiloso',
          'Silty Clay Loam': 'Franco argilo siltoso',
          'Sandy Clay': 'Argilo arenoso',
          'Silty Clay': 'Argilo siltoso',
          Clay: 'Argiloso',
          'Mix of Peat types': 'Turfa',
          'Coconut fiber': 'Fibra de coco',
          'Mixed Tuff': 'Outros substratos orgânicos',
          Alfafa: 'Alfafa',
          last_seen: 'Última visualização',
          recent_analog_values: 'Valores analógicos recentes',
          recent_rssi_values: 'Valores RSSI recentes',
          recent_battery_values: 'Valores recentes de bateria',
          recent_c1_values: 'Valores C1 recentes',
          modem_version: 'Versão do Modem',
          modem_hardware: 'Hardware de modem',
          iccid: 'ICCID',
          sample_interval: 'Intervalo de amostragem',
          micro_version: 'Versão micro',
          date: 'Data',
          value: 'Valor',
          sim_activation:
            'SIM sendo ativado, aguarde 10 minutos antes de testar novamente',
          enter_transmitter_id: 'Insira a identificação do transmissor',
          Cabbage: 'Repolho',
          Carrot: 'Cenoura',
          Cauliflower: 'Couve-flor',
          Celery: 'Salsão',
          Lettuce: 'Alface',
          Onion: 'Cebola',
          Spinach: 'Espinafre',
          Radish: 'Rabanete',
          Eggplant: 'Berinjela',
          'Sweet pepper': 'Pimentão',
          Tomato: 'Tomate',
          Cantaloupe: 'Melão',
          Cucumber: 'Pepino',
          'Pumpkin (Winter Squash)': 'Abóbora (de inverno)',
          Squash: 'Abóbora',
          Zucchini: 'Abobrinha',
          'Sweet Melon': 'Meão Doce',
          Beet: 'Beterraba',
          Cassava: 'Mandioca',
          Potato: 'Batata',
          'Sweet potato': 'Batata-doce',
          'Sugar Beet': 'Beterraba sacarina',
          Bean: 'Feijão',
          'Faba bean, broad bean': 'Feijão fava',
          'Green Gram': 'Grão de bico',
          Cowpea: 'Feijão fradinho',
          'Peanut (Groundnut)': 'Amendoim',
          Lentil: 'Lentilha',
          Pea: 'Ervilha',
          Soybean: 'Soja',
          Artichoke: 'Alcachofra',
          Asparagus: 'Aspargo',
          Cotton: 'Algodão',
          Flax: 'Linhaça',
          'Castorbean (Ricinus)': 'Castorbean (Ricinus)',
          Safflower: 'Cártamo',
          Sesame: 'Sésame',
          Sunflower: 'Girassol',
          Barley: 'Cevada',
          Oat: 'Aveia',
          Wheat: 'Trigo',
          Maize: 'Milho',
          Millet: 'Milheto',
          Sorghum: 'Sorgo',
          Rice: 'Arroz',
          Alfalfa: 'Alfafa',
          Bermuda: 'Grama bermuda',
          Sudan: 'Grama Sudan',
          Sugarcane: 'Cana-de-açúcar',
          Banana: 'Banana',
          Pineapple: 'Abacaxi',
          Grape: 'Uva',
          Hop: 'Lúpulo',
          Citrus: 'Citrus',
          Olive: 'Oliveira',
          Pistachio: 'Pistache',
          Walnut: 'Noz',
          'Cattails, Bulrush': 'Taboa',
          Mango: 'Manga',
          Grapefruit: 'Toranja',
          Garden: 'Plantas de Jardim',
          Grass: 'Grama',
          'Genus Citrus': 'Citrus (Gênero)',
          Avocado: 'Abacate',
          Coffee: 'Café',
          Corn: 'Milho',
          Lychee: 'Lichia',
          'Peach, Nectarine': 'Pêssego, Nectarina',
          Watermelon: 'Melancia',
          'Date Palm': 'Tâmara',
          Pomegranate: 'Romã',
          Cannabis: 'Cânhamo',
          Pepper: 'Pimenta',
          Blackberry: 'Amora',
          Strawberry: 'Morango',
          Pecan: 'Noz-pecã',
          installation_date: 'data de instalação',
          soil_water_deficit: 'Deficiência hídrica no solo',
          n_in_organic_matter: 'N na matéria orgânica',
          initial_concentrations: 'Concentrações iniciais',
          should_automate_irrigation: 'Automatize a irrigação',
          kc_harvest_date: 'Data de colheita',
          kc_harvest_date_invalid_data_message:
            'Insira uma data de colheita  válida',
          graphs: 'Gráficos',
          overview: 'Relatório',
          login_check_your_email: 'Verifique seu email',
          create_new_password: 'Criar nova senha',
          notifications: 'Notificações',
          version: 'Versão',
          account_settings: 'Configuração de conta',
          send_reset_code: 'Enviar código de redefinição',
          forgot_password_subtitle:
            'Insira seu email e enviaremos o código de redefinição de senha',
          login_title: 'Entrar',
          back: 'Voltar',
          estimated: 'Estimado',
          farm: 'Unidade Agricola',
          location: 'Localização',
          trasmitter_test: 'Teste do transmissor',
          new: '🎉 Novo!',
          download_the_app: 'Baixe o aplicativo para celular',
          take_the_app_to_the_field:
            'Leve N-Drip Connect como você para o campo',
          continue: 'Continuar',
          resend: 'Reenviar',
          min_temp: 'Temp Min',
          max_temp: 'Temp Max',
          etc: 'ETc',
          distribution: 'Distribuição',
          'my fields': 'Minhas lavouras',
          sensor_batch: 'Lote de sensores',
          transmission_interval: 'Intervalo de transmissão',
          meta_data: 'Metadados',
          start: 'Início',
          end: 'Fim',
          discharge: 'Vazão',
          'recent wfr samples': 'Valores recentes do contador de água ',
          street: 'Rua',
          satellite: 'Satélite',
          topography: 'Topografia',
          ndvi: 'NDVI',
          'choose map': 'Escolha no mapa',
          day_sunday: 'Domingo',
          day_monday: 'Segunda-feira',
          day_tuesday: 'Terça-feira',
          day_wednesday: 'Quarta-feira',
          Tday_thursday: 'Quinta-feira',
          day_friday: 'Sexta-feira',
          day_saturday: 'Sábado',
          planning: 'Planejamento',
          monitoring: 'Monitoramento',
          planning_days: 'Dias',
          planning_weeks: 'Semanas',
          planning_publish: 'Publicar',
          recent_errors_values: 'Valores de erros recentes',
          sim_last_event: 'Último evento do cartão SIM ',
          leaf_length: 'Comprimento foliar ',
          stress_level_no_stress: 'Sem estresse',
          next_actions: 'Próximas ações',
          tomorrow: 'Amanhã',
          later: 'Mais tarde',
          coming_soon: 'Em breve',
          no_planner_events: 'Sem ações futuras neste momento',
          history_graph: 'Gráfico histórico',
          planning_planned: 'PLANEJADO',
          planning_used: 'EXECUTADO',
          event: 'evento',
          hcl: 'HCl',
          hpo: 'HPO',
          note: 'Observação',
          planning_note_placeholder: 'Escreva aqui sua mensagem',
          sensors: 'sensores',
          valves: 'válvulas',
          type: 'tipo',
          'coming up': 'chegando',
          locate_coordinates: 'Localizar coordenadas',
          ignore_sensor_during_calculations: 'Ignorar sensor nos cálculos',
          ignore_sensor_during_calculations_and_hide:
            'Ocultar e ignorar sensor nos cálculos',
          consider_as_flow_meter: 'Consider como medidor de vazão',
          water_potential_graph: 'Gráfico de potencial hídrico',
          edit: 'Editar',
          alerts: 'Alertas',
          stop_data_collection: 'Interromper a coleta de dados',
          accumulated_water_input: 'Entrada de água acumulada',
          precipitation: 'Chuva',
          water_potential: 'Potencial hídrico',
          minimum: 'mínimo',
          maximum: 'máximo',
          max_bigger_than_min: 'Valor máximo deve ser maior que o valor mínimo',
          min_smaller_than_max:
            'Valor máximo deve ser menor que o valor mínimo',
          last_year: 'Último ano',
          moderator: 'Moderador',
          designed_pressure: 'Pressão estimada',
          field_size: 'Tamanho do campo',
          duplicate_field: 'duplicar campo',
          duplicate: 'duplicar',
          offset: 'Offset',
          days_from_sowing: 'Dias após semeadura',
          Field_details_days_in_season: 'Dias em período de safra',
          show_archived: 'Mostrar campos arquivados',
          events_list: 'Lista de eventos',
          notes: 'Comentários',
          week_total: 'Total semanal',
          season_total: 'Total safra',
          no_reported: 'Nenhum evento adicionado',
          report_event: 'Adicionar evento',
          save: 'salvar',
          rain: 'Chuva',
          starting_time: 'hora de início',
          fertilization: 'fertilização',
          reported: 'Adicionados',
          plant_height: 'Altura de planta',
          fert_tank_height: 'Nível do tanque de fertilização',
          Fert_tank_volume: 'Volume do tanque de fertilização',
          allow_notifications: 'Permitir notificações',
          notifications_channels: 'Em quais canais você será notificado',
          event_type: 'Tipo de evento',
          push: 'Ativar',
          recommendations: 'Recomendações',
          field_notifications:
            'Quais notificações você receberá para cada campo',
          none: 'Nenhum',
          notifications_phase:
            'Controlar os tipos de notificação e canais pelos quais serei notificado, por campo',
          alerts_tooltip:
            'Os alertas sinalizam eventos adversos que exigem ação imediata para proteção das lavouras',
          recent_connectivity_values: 'Valores recentes de conectividade',
          alerts_message_continuous_irrigation_notification_center_title:
            'Alerta de Irrigação contínua - fieldX.',
          alerts_message_continuous_irrigation_notification_center_body:
            'Detectamos irrigação contínua há mais de 2 dias para o campo. Para proteção das lavouras, recomendamos verificar se o campo está sendo overirrigado.',
          alerts_message_sensor_extreme_dry_conditions_notification_center_title:
            'Condições extremas de seca detectadas no campo - Dados recebidos do sensorX',
          alerts_message_long_dry_period_notification_center_title:
            'Longo período sem irrigação - Recomendamos a irrigação do fieldX',
          alerts_message_sensor_extreme_dry_conditions_notification_center_body:
            'Os dados recebidos pelo sensorX indicam condição extremamente seca no campo.Para assegurar dados precisos e proteção das lavouras, recomendamos verificar o plano de irrigação e o sistema de irrigação ao redor do sensor.',
          alerts_message_long_dry_period_notification_center_body:
            'O fieldX não recebe água há 10 dias, o que pode resultar em estresse hídrico. Para proteção das lavouras, recomendamos irrigar o campo seguindo seu plano de irrigação N-Drip.',
          alerts_message_drying_trend_notification_center_title:
            'Detectamos divergências em relação às recomendações de irrigação planejadas para o campo',
          alerts_message_wetting_trend_notification_center_title:
            'Tendência de umedecimento detectada no fieldX',
          alerts_message_drying_trend_notification_center_body:
            'Observamos tendência decrescente no potencial hídrico do fieldX, abaixo do nível recomendado. Para otimização das lavouras, revise e ajuste seu plano de irrigação de acordo com as recomendações de N-Drip.',
          alerts_message_wetting_trend_notification_center_body:
            'Observamos tendência crescente do potencial hídrico no fieldX, acima do nível recomendado. Para otimização das lavouras, revise e ajuste seu plano de irrigação atual de acordo com as recomendações de N-Drip.',
          alerts_message_excessive_heat_notification_center_title:
            'Temperatura do ar elevada',
          alerts_message_excessive_heat_notification_center_body:
            'Há a previsão de ocorrência de temperatura do ar elevada para a fieldX para os próximos dias. Para otimização das lavouras revise e ajuste seu plano de irrigação atual de acordo com nossas recomendações atualizadas.',
          alerts_message_extreme_cold_notification_center_title:
            'Alerta de Onda de Frio',
          alerts_message_extreme_cold_notification_center_body:
            'Uma onda de frio significativa está prevista para ocorrer na área do fieldX nos próximos dias. Para proteger sua lavoura dos danos causados pela geada, revise e ajuste seu plano de irrigação de acordo com as nossas recomendações atualizadas.',
          notifications_welcome_message_title:
            'Bem-vindo ao novo painel de notificações',
          notifications_welcome_message_body:
            'Fique por dentro de eventos importantes atuais e esperados em suas lavouras no desktop e dispositivos móveis',
          alerts_message_dryland_long_dry_period_notification_center_title:
            'Longo período seco detectado',
          alerts_message_dryland_long_dry_period_notification_center_body:
            'O fieldX não recebe água há 10 dias, o que pode resultar em estresse hídrico.',
          alerts_message_dryland_sensor_exterme_dry_notification_center_title:
            'Condições extremas de seca detectadas no campo - Dados recebidos do sensorX',
          alerts_message_dryland_sensor_exterme_dry_notification_center_body:
            'Os dados recebidos pelo sensorX indicam condição extremamente seca no campo.',
          alerts_message_dryland_drying_trend_notification_center_title:
            'Tendência de seca detectada no seu campo',
          alerts_message_dryland_drying_trend_notification_center_body:
            'Observamos tendência decrescente no potencial hídrico do fieldX, o que pode resultar em estresse hídrico',
          alerts_message_dryland_wetting_trend_notification_center_title:
            'Tendência de umedecimento detectada no seu campo',
          alerts_message_dryland_wetting_trend_notification_center_body:
            'Observamos tendência crescente no potencial hídrico do fieldX, o que pode resultar em estresse hídrico',
          alerts_message_dryland_excessive_heat_notification_center_title:
            'Alerta de Onda de Calor',
          alerts_message_dryland_excessive_heat_notification_center_body:
            'Uma onda de calor significativa está prevista para ocorrer na área do fieldX nos próximos dias.',
          alerts_message_dryland_extreme_cold_notification_center_title:
            'Alerta de Onda de Frio',
          alerts_message_dryland_extreme_cold_notification_center_body:
            'Uma onda de frio significativa está prevista para ocorrer na área do fieldX nos próximos dias.',
          alerts_message_dryland_ndvi_notification_center_title:
            'Redução da atividade vegetativa',
          alerts_message_dryland_ndvi_notification_center_body:
            'Análises recentes do histórico de imagens de NDVI revelaram uma redução significativa no crescimento vegetativo no fieldX. Para otimizar a saúde de sua lavoura, revise suas práticas atuais de irrigação.',
          no_notifications: 'No momento não há notificações',
          all_fields: 'Todos os campos',
          current_field: 'Campo atual',
          planner_days_shorts_sun: 'Dom',
          planner_days_shorts_mon: 'Seg',
          planner_days_shorts_tue: 'Ter',
          planner_days_shorts_wed: 'Qua',
          planner_days_shorts_thu: 'Qui',
          planner_days_shorts_fri: 'Sex',
          planner_days_shorts_sat: 'Sab',
          shorts_months_january: 'Jan',
          shorts_months_february: 'Fev',
          shorts_months_march: 'Mar',
          shorts_months_april: 'Abr',
          shorts_months_may: 'Mai',
          shorts_months_june: 'Jun',
          shorts_months_july: 'Jul',
          shorts_months_august: 'Ago',
          shorts_months_september: 'Set',
          shorts_months_october: 'Out',
          shorts_months_november: 'Nov',
          shorts_months_december: 'Dez',
          dont_show_again: 'Não mostrar novamente',
          notifications_settings: 'Configurações de notificação',
          read_more: 'Leia mais',
          show_less: 'Mostrar menos',
          distribution_menu_select: 'Selecionar distribuição',
          farming_unit_menu_select: 'Selecionar Unidade Agrícola',
          field_menu_select: 'Selecionar campo',
          recent_field: 'Campo recente',
          discharge_test: 'Teste de Vazão',
          planner_season_start: 'Início da safra',
          planner_season_end: 'Fim da safra',
          planner_growth_phase_initial: 'Inicial',
          planner_growth_phase_development: 'Desenvolvimento',
          planner_growth_phase_middle: 'Médio',
          planner_growth_phase_late: 'Final',
          planner_export_button: 'Exportar',
          planner_add_event_button: 'Adicionar evento',
          planner_water_treatment: 'Tratamento de água',
          planner_add_button: 'Adicionar',
          create_planner_item: 'Adicionar novo evento ',
          update_planner_item: 'Atualizar evento',
          planner_start_time: 'Hora de início',
          dashboard_move_to_archive: 'Mover para o arquivo',
          dashboard_unarchived: 'Desarquivado',
          Insights_title_county: 'Condado',
          Insights_group_by: 'Agrupado por',
          edit_field_tank_type: 'Tipo do reservatório de água',
          edit_field_measured_irrigation_rate: 'Taxa de irrigação medida',
          edit_field_custom_irrigation_rate: 'Customizar taxa de irrigação',
          edit_field_representative_input_checkbox:
            'Utilize os valores medidos de taxa de irrigação quando disponíveis. Isso impactará o planejamento de irrigação',
          wtr_reported_successfully: 'Tratamento de água reportado com sucesso',
          kc_phases_stress_checkbox: 'Aplicar estresse',
          kc_phases_stress_tooltip:
            'Iniciar um período planejado de estresse hídrico de acordo com a seguinte redução percentual na ETc',
          edit_field_irrigation_timeframe_source_soil:
            'limitação pelo tipo de solo',
          edit_field_irrigation_timeframe_source_irrigation_rate:
            'taxa de irrigação',
          edit_field_irrigation_timeframe_source_user: 'definido por usuário',
          edit_field_irrigation_timeframe_tooltip: 'Limitação original:',
          source: 'Origem',
          discharge_result_exceeding_hint:
            'O resultado do teste diverge da curva de tendência da vazão',
          Timeframe_end_of_season: 'Fim da safra',
          Farming_unit_entity_basic_info: 'Informações básicas',
          Farming_unit_entity_add_new_button: 'Adicionar novo',
          WT_protocol_edit_field_water_properties_section_title:
            'Propriedades da água',
          WT_protocol_edit_field_water_source: 'Fonte de água',
          WT_protocol_edit_field_water_conveyance: 'Transporte',
          WT_protocol_edit_field_water_analysis_section_title:
            'Análise de água',
          WT_protocol_edit_field_water_analysis_section_subtitle:
            'Ver intervalos de recomendação em cada célula',
          WT_protocol_edit_field_water_analysis_table_materials: 'Materiais',
          WT_protocol_edit_field_water_analysis_table_score: 'Pontuação',
          WT_protocol_edit_field_water_analysis_table_low: 'Baixo',
          WT_protocol_edit_field_water_analysis_table_medium: 'Médio',
          WT_protocol_edit_field_water_analysis_table_high: 'Alto',
          WT_protocol_edit_field_water_analysis_table_organic: 'Orgânico',
          WT_protocol_edit_field_water_analysis_table_calc: 'Cálculo',
          WT_protocol_edit_field_water_analysis_table_fe: 'Ferro',
          WT_protocol_edit_field_material_concentrations:
            'Concentração dos materiais',
          WT_protocol_edit_field_acid_titration: 'Titulação ácida',
          Farming_unit_entity_tooltip:
            'Elementos desabilitados podem ser editados a partir da unidade agrícola',
          WT_timing_recommendation_after_irrigation_starts:
            'Após 30min de irrigação',
          WT_timing_recommendation_last_hour: 'Última hora de irrigação',
          Fert_sensor_fertlization_type: 'Tipo de fertilizante',
          fertilizer_meter_id: 'Identificação do medidor de fertilizante',
          add_fertilizer: 'Adicionar fertilizante',
          Farming_unit_entity_all: 'Todas',
          basic_info: 'Información basica',
          allow_dynamic_updates: 'Permitir atualizações dinâmicas',
          define_allow_dynamic_updates:
            'As recomendações serão afetadas por mudanças na irrigação a qualquer momento.',
          KC_phases_title: 'Fase de crescimento',
          KC_phases_title_pre_season: 'Pré-safra',
          KC_phases_title_in_between_seasons: 'Entressafra',
          KC_phases_season_end: 'Fim de safra',
          KC_phases_phase_tooltip_start: 'Início',
          KC_phases_phase_tooltip_end: 'Fim',
          KC_phases_button_save: 'Salvar',
          KC_phases_button_reset: 'Reiniciar',
          KC_phases_stress_inidication: 'Irrigação com déficit controlado',
          KC_phases_button_edit: 'Editar',
          KC_phases_fast_flow: 'Configurar fase atual',
          KC_phases_now_indication: 'Agora',
          KC_phases_estimated_short: 'Est.',
          KC_phases_estimated_season_end: 'Provável fim de safra',
          KC_phases_edit_mode_default_title:
            'Clique na fase para ajustar sua duração',
          kc_phases_error_message:
            'O valor adicionado está em conflito com outras variáveis ',
        },
      },
      zh: {
        translations: {
          soil_water_deficit: '土壤水份缺乏状况',
          login_registration_confirmation_message:
            '点击您邮箱中收到的确认链接以确认您在我们系统里的登记',
          login_registration_after_confirmation_message: '確認之后，去往',
          login_registration_after_confirmation_message_2: '登錄',
          login_enter_your_email: '進入您的邮箱',
          login_enter_your_password: '輸入您的密码',
          login_enter_a_valid_email: '輸入一个当前使用的邮箱账号',
          login_enter_a_valid_confirmation_code: '輸入一个确认密码',
          login_forgot_password: '忘記密码？',
          login_enter_verification_code_message:
            '輸入您在邮箱账号中收到的认证码',
          login_enter_confirmation_code_message:
            '輸入您在邮箱账号中收到的确认密码',
          login_enter_confirmation_code: '輸入确认密码',
          confirmation_code: '確認密码',
          login_new_password: '新密码',
          login_enter_a_valid_password: '輸入一个有效的密码',
          login_dont_have_account_message: '未开录账户？',
          login_sign_up: '登入',
          login_enter_first_name: '輸入您的名',
          login_enter_last_name: '輸入您的姓',
          login_enter_phone_number: '輸入您的电话号码',
          login_password_limitation_message:
            '密碼最起码8位数字并包括一个大写和一个小写字母',
          account_first_name_invalid_data_message: '輸入有效的名',
          account_last_name_invalid_data_message: '輸入有效的姓',
          account_phone_number_invalid_data_message: '輸入有效的电话号码',
          account_email_invalid_data_message: '輸入有效的电子邮箱',
          account_notifications: '收到提醒信息？',
          loading: '在下载。。。',
          kc_developments: '生長',
          kc_auto_generate: '自動生成',
          kc_crop_type_invalid_data_message: '輸入作物种类',
          kc_variety: '品種',
          kc_variety_invalid_data_message: '輸入品種',
          kc_feature: '特徵',
          kc_feature_invalid_data_message: '輸入特徵',
          kc_sow_date: '播種日期',
          kc_sow_date_invalid_data_message: '輸入播種日期',
          kc_harvest_date: '收穫日期',
          kc_harvest_date_invalid_data_message: '輸入收穫日期',
          kc_kc_table: 'KC表格',
          kc_by_month: '按月计',
          kc_by_growth_phase: '按生长阶段计',
          kc_add_development_phase: '增加一个生长阶段',
          kc_phase_title_placeholder: '加入阶段名称',
          kc_first_phase_starts: '第一阶段开始日期',
          edit_field_choose_area_message: '需要為地块选择一个区域',
          edit_field_field_name: '地塊名称',
          edit_field_last_recommendation: '最後一次的建议',
          edit_field_no_fields: '沒有地塊可显示 😟',
          edit_field_field_name_invalid_data_message: '輸入有效的地塊名称',
          edit_field_area_invalid_data_message:
            '用左边下拉菜单标注您的地塊范围',
          edit: '编辑',
          edit_field_time_zone_invalid_message: '輸入时区',
          edit_field_water_meter_sensors: '流量計探头',
          edit_field_installation_date: '安装日期',
          edit_field_installation_date_invalid_message: '輸入安装日期',
          edit_field_contract_duration: '合同結束日期',
          edit_field_contract_duration_invalid_message: '合同期限必须大于今天',
          edit_field_delete_field_message: '確認需要删除该地块？',
          cluster: '集合',
          none_default: '沒有-默認',
          irrigate_command: '开始灌溉',
          get_ready_to_irrigate: '準備开始灌溉',
          dont_irrigate_command: '不需要灌溉',
          stress_level_very_high: '缺水状况非常严重',
          stress_level_high: '缺水状况严重',
          stress_level_getting_low: '缺水情況好轉',
          stress_level_optimal: '土壤濕潤状态最佳',
          stress_level_no_stress: '未发生缺水状况',
          no_recommendation_available: '沒有已提出的建議',
          no_recommendation: '沒有建議',
          expiration_date: '合同终止日期',
          soil_type: '土壤类型',
          crop_type: '作物类型',
          next_actions: '下一措施',
          take_the_app_to_the_field: '帶着Ndrip Connect 到田间',
          new: '� 上新！',
          history_graph: '历史曲线',
          download_the_app: '下载手机应用软件',
          coming_soon: '即將推出',
          dashboard_irrigation_amount: '灌溉水量',
          dashboard_my_fields: '我的地块',
          dashboard_delete_field: '刪除',
          dashboard_copy_field_id: '複製ID',
          dashboard_edit_field: '編輯',
          dashboard_add_field: '增加地块',
          dashboard_new_field: '新地块',
          dashboard_loading_data_message:
            '我们正在收集相关信息，预计需要6-8小时',
          no_planner_events: '目前为止没有计划实施的措施',
          management_distribution_units_management: '分佈单元管理',
          transmitter_admin_page: '傳感器测试',
          management_admins_only: '仅限管理员',
          management_farming_units_management: '種植单元管理',
          management_delete_distribution_message: '確認删除该分佈？',
          management_manage_distribution_users_title: '管理（區域分佈）用户',
          management_enter_a_valid_username:
            '輸入有效的用戶名 - ex. user@domain.com',
          management_enter_a_valid_distribution_name: '輸入有效的地域分布名称',
          management_enter_a_valid_farming_unit_name: '輸入有效的種植單位名称',
          management_enter_a_valid_region: '輸入有效的地区',
          management_farming_unit_name: '種植单元名称',
          management_farming_unit_salesforce_id: '銷售人员ID',
          management_distribution_name: '地區分部名称',
          login_page: '登錄頁',
          time_zone: '時間區',
          tensiometers: '水壓計',
          support_planner: '支持計劃表',
          distribution_unit: '分佈單位',
          farming_unit: '種植單位',
          field_amount: '地塊數量',
          region: '區域',
          actions: '行動',
          close: '結束',
          user: '用戶',
          role: '角色',
          admin: '管理員',
          viewer: '觀察者',
          moderator: '調解者',
          account: '賬戶',
          logout: '登出',
          first_name: '名',
          last_name: '姓',
          phone_number: '電話號碼',
          email: '郵箱',
          units: '單位',
          area: '面積',
          gdd: 'GDD',
          leaf_length: '叶子长度',
          "et0'": 'ETo',
          leached: '滲濾量',
          plant: '植物',
          soil: '土壤',
          temperature: '溫度',
          weight: '重量',
          pressure: '壓力',
          length: '長度',
          volume: '體積',
          estimated_nitrogen_values: '估計的含氮量',
          temperature_celsius: '攝氏度',
          temperature_fahrenheit: '華氏度',
          weight_kg: '公斤',
          weight_lb: '磅',
          weight_tonne: '噸',
          weight_short_ton: '短噸',
          pressure_kpa: '千帕',
          pressure_psi: 'PSI',
          pressure_cbar: 'Bar',
          pressure_mm: '毫米',
          pressure_pa: 'Pa',
          length_mm: '毫米',
          length_inch: '英寸',
          volume_cubic_metre: '立方米',
          volume_gallon: '加侖',
          volume_bushel: '蒲式耳',
          volume_acre_foot: '英亩英尺',
          volume_acre_inch: '英亩英寸',
          volume_megaliter: '兆升',
          phase: '階段',
          kc: 'Kc',
          duration: '時長',
          days: '天',
          months_january: '一月',
          months_february: '二月',
          months_march: '三月',
          months_april: '四月',
          months_may: '五月',
          months_june: '六月',
          months_july: '七月',
          months_august: '八月',
          months_september: '九月',
          months_october: '十月',
          months_november: '十一月',
          months_december: '十二月',
          show_archived: '顯示存檔',
          choose_date: '選擇日期',
          last_day: '最後一天',
          last_week: '最後一周',
          last_two_weeks: '最後兩周',
          last_month: '最後一個月',
          last_six_months: '最后六个月',
          last_year: '去年',
          activity_dates: '活動日期',
          supporting_data: '支撐數據',
          water_stress_level: '缺水状况',
          fertilization_gauge_title: '氮含量水平',
          map_view: '地图视角',
          serial_no: '序列号',
          sensor_serial_no: '探头系列号',
          sensor_latitude: '探头经度',
          sensor_longitude: '探头纬度',
          water_meter_units: '立方米数每個脈衝',
          name: '名稱',
          latitude: '經度',
          longitude: '緯度',
          create: '生成',
          update: '更新',
          field: '地塊',
          cancel: '取消',
          delete: '刪除',
          ok: 'OK',
          confirmation: '確認',
          confirm: '確認',
          yes: '是',
          no: '否',
          home: '主頁',
          change: '更改',
          forgot: '忘記',
          password: '密碼',
          login: '登錄頁',
          prefix: '前綴',
          register: '注冊',
          settings: '設置',
          based_on: '基於',
          amount: '數量',
          date_and_time: '日期和时间',
          report: '報告',
          hours: '小時數',
          report_irrigation: '報告灌溉量',
          report_nitrogen: '報告施肥量',
          irrigation_reported_succesfully: '灌溉报告成功发送',
          fertilization_reported_succesfully: '施肥報告成功發送',
          is_manure: '用的是粪肥？',
          irrigation: '灌溉',
          select_sensors: '選择探头',
          select_all: '選擇所有',
          manure: '糞肥',
          nitrogen: '氨',
          fertilization_history: '施肥历史',
          water_pressure_history: '作物根系水势历史数据',
          designed_pressure: '設計水壓',
          support_ndvi: '支持NDVI?',
          support_coup_model: '支持Coup模型？',
          n_in_organic_matter: '有机质中的氮含量',
          initial_concentrations: '開始含量',
          should_automate_irrigation: '自动灌溉',
          location: '地點',
          i_agree_to: '同意',
          terms_and_conditions: '條款和条件',
          language: '語言',
          last_update: '最後一次更新：',
          hours_ago: '0} 小时前',
          hour_ago: '0} 小时前',
          day_ago: '0} 天前',
          days_ago: '0} 天前',
          minute_ago: '0} 分钟前',
          minutes_ago: '0} 分钟前',
          today: '今天',
          yesterday: '昨天',
          enter_transmitter_id: '輸入傳輸器ID',
          transmitter_id: '傳輸器ID',
          sim_activation: '芯片正在启动，请稍等10分钟再进行下一次测试',
          last_seen: '最後一次显示：',
          recent_analog_values: '最近的模拟数据',
          recent_connectivity_values: '最近的連接性数据',
          recent_battery_values: '最近的电池数据',
          recent_c1_values: '最近的C1数据',
          recent_errors_values: '最近的错误数据',
          installation_date: '安裝日期',
          modem_version: '制解调器版本',
          modem_hardware: '制解调器硬件',
          iccid: 'ICCID',
          sensor_batch: '探頭批次号',
          transmission_interval: '信號传输间隔',
          sample_interval: '取樣間隙',
          micro_version: '微觀版本',
          sim_last_event: '最后一次SIM卡活动',
          date: '日期',
          value: '數據',
          test: '測試',
          Sand: '沙土',
          'Loamy Sand': '壤沙',
          'Sandy Loam': '沙壤土',
          Loam: '壤土',
          Silt: '砂',
          'Silty Loam': '砂壤土',
          'Sandy Clay Loam': '砂質黏土',
          'Clay Loam': '粘壤土',
          'Silty Clay Loam': '砂粘壤土',
          'Sandy Clay': '砂質粘土',
          'Silty Clay': '粉質粘土',
          Clay: '黏土',
          'Mix of Peat types': '混合泥炭种类',
          'Coconut fiber': '椰子纤维',
          'Mixed Tuff': '混合凝灰岩',
          water_potential_graph: '水势曲线',
          Cabbage: '捲心菜',
          Carrot: '胡羅卜',
          Cauliflower: '菜花',
          Celery: '芹菜',
          Lettuce: '生菜',
          Onion: '洋葱',
          Spinach: '菠菜',
          Radish: '羅卜',
          Eggplant: '茄子',
          'Sweet pepper': '甜椒',
          Tomato: '西紅柿',
          Cantaloupe: '密瓜',
          Cucumber: '黃瓜',
          'Pumpkin (Winter Squash)': '南瓜（冬季南瓜）',
          Squash: '南瓜',
          Zucchini: '西葫芦',
          'Sweet Melon': '甜瓜',
          Beet: '甜菜',
          Cassava: '木薯',
          Potato: '土豆',
          'Sweet potato': '紅薯',
          'Sugar Beet': '甜菜',
          Bean: '豆角',
          'Faba bean, broad bean': '寬扁豆',
          'Green Gram': '綠豆',
          Cowpea: '豇豆',
          'Peanut (Groundnut)': '花生',
          Lentil: '扁豆',
          Pea: '青豆',
          Soybean: '黃豆',
          Artichoke: '朝鮮蓟',
          Asparagus: '蘆筍',
          Cotton: '棉花',
          Flax: '亞麻籽',
          'Castorbean (Ricinus)': '蓖麻子',
          Safflower: '紅花',
          Sesame: '芝麻',
          Sunflower: '向日葵',
          Barley: '大麥',
          Oat: '燕麥',
          Wheat: '小麥',
          Maize: '玉米',
          Millet: '小米',
          Sorghum: '高粱',
          Rice: '水稻',
          Alfalfa: '苜蓿',
          Bermuda: '百慕大',
          Sudan: '蘇丹',
          Sugarcane: '甘蔗',
          Banana: '香蕉',
          Pineapple: '菠蘿',
          Grape: '葡萄',
          Hop: '跳',
          Citrus: '柑橘',
          Olive: '橄欖',
          Pistachio: '開心果',
          Walnut: '核桃',
          'Cattails, Bulrush': '香蒲',
          Mango: '芒果',
          Grapefruit: '柚子',
          Garden: '花園',
          Grass: '草地',
          'Genus Citrus': '柑橘属类',
          Avocado: '牛油果',
          Coffee: '咖啡',
          Corn: '玉米',
          Lychee: '荔枝',
          'Peach, Nectarine': '桃子',
          Watermelon: '西瓜',
          'Date Palm': '蜜棗',
          Pomegranate: '石榴',
          Cannabis: '大麻',
          Pepper: '胡椒',
          Blackberry: '黑莓',
          Strawberry: '草莓',
          Pecan: '胡桃',
          start: '開始',
          end: '結束',
          discharge: '排放水量',
          'recent wfr samples': '最近的流量计读数',
          day_sunday: '星期日',
          day_monday: '星期一',
          day_tuesday: '星期二',
          day_wednesday: '星期三',
          day_thursday: '星期四',
          day_friday: '星期五',
          day_saturday: '星期六',
          planning: '计划',
          monitoring: '监测',
          planning_days: '天',
          planning_weeks: '星期',
          planning_publish: '公布',
          planning_planned: '計劃的',
          planning_used: '实际读取数据',
          tomorrow: '明天',
          later: '稍后',
          event: '发生事件',
          events: '事件',
          fertilization: '施肥',
          hcl: '鹽酸',
          hpo: '次氯酸钠',
          note: '备注',
          planning_note_placeholder: '请留言',
          "accumulated this week'": '本周纍計',
          "accumulated this season'": '本季度纍計',
          sensors: '探頭',
          valves: '閥門',
          type: '類型',
          'coming up': '建议的工作内容',
          notify: '通知',
          irrigation_system: '灌溉系統',
          irrigation_type: '灌溉類型',
          irrigation_rate: '灌溉量',
          irrigation_time_frames: '灌溉時間範圍',
          define_time_frames: '設置可選擇的灌溉時間範圍',
          start_day: '開始日期',
          start_hour: '開始小時',
          end_hour: '結束小時',
          time_frame_conflict: '選擇的灌溉時間範圍與現有的灌溉時間範圍衝突',
          select_different_hour: '請選擇不同的小時',
          select_different_day: '請選擇不同的天',
          no_time_frames: '沒有灌溉時間範圍限制',
          for_this_field: '目前本地塊',
          locate_coordinates: '当地坐标',
          ignore_sensor_during_calculations: '计算过程中忽略',
          ignore_sensor_during_calculations_and_hide: '计算过程中忽略並隱藏',
          consider_as_flow_meter: '视为水表',
          alerts: '预警',
          stop_data_collection: '数据采集',
          accumulated_water_input: '水势曲线',
          precipitation: '沉淀',
          water_potential: '水势',
          petiole: '叶柄',
          minimum: '最小',
          maximum: '最大',
          max_bigger_than_min: '最大值必须大于最小值',
          updated: '更新的',
          not_yet_set: '仍未設置',
          missing_values: '缺少數據',
          start_date: '開始日期',
          end_date: '結束日期',
          fertigation_concentrations: '施肥濃度',
          no_fertigation_concentrations_message: '沒有施肥濃度',
          general_coup_settings: 'Coup模型一般設置',
          days_from_sowing: '從播種日開始的天數',
          field_size: '地塊面積',
          sensor_type: '探頭類型',
          sensor_sub_type: '探頭分類型',
          dashboard_move_to_archive: '存入檔案',
          dashboard_unarchived: '未存檔',
          alphabetic: '按字母順序',
          duplicate_field: '複製地塊',
          duplicate: '複製',
          sort_by: '分類',
          Almond: '杏仁',
          Apple: '苹果',
          Arugula: '芝麻菜',
          Blueberry: '蓝莓',
          Broccoli: '西兰花',
          'Faba Bean, Broad Bean': '寬扁豆',
          Fig: '无花果',
          Garlic: '大蒜',
          Guayule: '銀膠菊',
          Jojoba: '荷荷芭油',
          Kiwi: '奇异果',
          Loquat: '枇杷',
          Mandarin: '橙子',
          Moringa: '辣木',
          Nectarine: '油桃',
          Orange: '橘子',
          Peach: '桃子',
          Peony: '牡丹花',
          Raspberry: '覆盆子',
          'Sweet Cherry': '甜樱桃',
          'Sweet Pepper': '甜椒',
          'Sweet Potato': '紅薯',
          '1st Cutting Cycle': '第一次收割',
          'Other Cutting Cycles': '其他收割循环',
          'Clay (Red Oxisol) (Pivot Irrigation)': '粘土（红氧化物）噴灌灌溉',
          'Clay (Red Oxisol)': '粘土（红氧化物）',
          'Coconut Fiber': '椰子纤维',
          'Silt Loam': '淤泥质土壤',
          'Clay (Ramot Gonen Pardes)': '黏土（Ramot Gonen Pardes）',
          'Clay Loam (6mm)': '粘壤土（6毫米）',
          'Sandy Clay (Shefayim)': '砂粘土',
          'Clay 1000mm': '黏土 1000毫米',
          'Clay Loam Et': '粘壤土Et',
          'Loam (Agroterra)': '粘壤土（6毫米）',
          'Clay (Ramot Gonen)': '粘土 （ Ramot Gonen)',
          'Clay (Red Oxisol) Au': '粘土 （ Red Oxisol) Au',
          'Clay Loam (Peanuts)': '粘壤土（花生）',
          'Clay (Adashim)': '黏土（Adashim)',
          'Clay Loam Au': '粘壤土  Au',
          'Clay (Pivot Irrigation)': '黏土 （旋臂灌溉系統）',
          'Clay Au': '黏土AU',
          'Clay (Cotton)': '黏土（棉花）',
          'Loam (6mm)': '壤土 （6毫米）',
          'Clay Loam (Daliot)': '粘穰土 （Daliot)',
          'Sand Ornamental': '沙飾',
          'Clay Loam (Pivot Irrigation)': '粘壤土（旋臂灌溉系統）',
          'Mix Of Peat Types': '合泥炭种类',
          'Clay Loam (Ak-Chin)': '粘壤土（Ak-Chin)',
          Irrigated: '已灌溉的',
          Planner: '計劃表',
          'Nitrogen graph': '氮含量曲綫',
          password_changed_successfully: '密碼已成功更改',
          week: '周',
          season: '種植季',
          etc: '等等',
          report_event: '報告事件的發生',
          select_water_input: '選擇水量',
          save: '保存',
          measurement: '計量',
          starting_time: '開始時間',
          number_of_fields: '地塊數量',
          total_area: '總面積',
          total_water_usage: '縂用水量',
          total_fertilizers_usage: '縂化肥用量',
          total_nitrogen_leached: '縂氮流失量',
          crop_types: '作物種類',
          fertilization_reported_successfully: '成功報告施肥過程',
          report_rain: '報告降雨',
          rain_reported_successfully: '成功報告降雨',
          no_cluster: '沒有地塊集合',
          offset: '偏移',
          create_planner_item: '增加新的計劃項',
          update_planner_item: '更新計劃項',
          containing: '包含',
          reported: '已經報告',
          events_list: '事件清單',
          notes: '備注',
          week_total: '周總計',
          season_total: '季度總計',
          no_reported_events: '沒有報告的事件發生',
          free_search: '自由搜索',
          search_by_field: '以地塊查詢',
          test_now: '現在測試',
          testing: '測試中',
          rain: '降雨',
          transmitters: '傳感器',
          pressure_over_time: '壓力隨時間變化',
          discharge_over_time: '流量隨時間變化曲綫',
          overview: '總覽',
          graphs: '圖表',
          map: '地圖',
          selected_field_only: '僅限選擇的地塊',
          select_transmitter: '選擇傳感器',
          to_show_data: '顯示數據',
          now: '現在',
          farming_type: '種植類型',
          plant_height: '作物高度',
          recent_rssi_values: '近期RSSI數值',
          recent_rsrp_values: '近期 RSRP數值',
          recent_rsrq_values: '近期RSRQ數值',
          recent_ber_values: '近期BER數值',
          meta_data: 'Meta數據',
          login_check_your_email: '请查收邮件',
          create_new_password: '生成一个新的密码',
          version: '版本',
          account_settings: '账户设置',
          send_reset_code: '发送重置口令',
          forgot_password_subtitle:
            '输入你的邮箱，我们将发送一个口令重新设置你的密码',
          login_title: '登录',
          back: '返回',
          estimated: '估計的',
          farm: '农场或地塊',
          trasmitter_test: '传输器测试',
          continue: '继续',
          resend: '重新发送',
          min_temp: '最低温度',
          max_temp: '最高温度',
          distribution: '分布',
          street: '街道',
          satellite: '卫星',
          topography: '地形图',
          'choose layer': '选择显示层面',
          coordinates: '坐标',
          support: '客服',
          'leave message': '请留言',
          send: '发送',
          'help message title': '您需要什么帮助？',
          'message sent successfully': '信息已成功发送',
          'something went wrong': '系统出现错误',
          set_as_manure: '設置為肥料',
          hour: '小時',
          show_more: '顯示更多',
          earlier: '之前',
          upcoming: '未来的工作内容',
          history: '历史',
          Urea: '尿素',
          'UAN 32': ' UAN 32',
          'Ammonium sulfate': '硫酸銨',
          'Ammonium nitrate': '硝酸銨',
          'Anhydrous ammonia': '無水銨',
          'Mono ammonium phosphate': '磷酸一銨',
          'Di ammonium phosphate': '磷酸二銨',
          'Calcium nitrate': '硝酸鈣',
          'Potash (MAP)': '鉀肥(MAP)',
          Manure: '肥料',
          Superphosphate: '過磷酸鈣',
          custom: '自行設置',
          'please select': '請選擇',
          success: '成功',
          height: '高度',
          metric_india: '公制（印度）',
          metric_israel: '公制（以色列）',
          metric_australia: '公制（澳大利亞）',
          metric: '公制',
          imperial: '美製',
          unit_system: '單位系統',
          recommendations_unit: '建議單位',
          transmitter_name: '傳感器名稱',
          sensor_name: '探頭名稱',
          'Planned irrigation': '預設的灌溉',
          'select fertilizer type': '選擇化肥種類',
          login_enter_your_email_forgot_password:
            '輸入你的郵箱地址我們將給你發送一個重新設置密碼的代碼',
          privacy_policy: '隱私條款',
          recommendations_tooltip: '建議可以幫助優化產量並改進決策過程的',
          allow_notifications: '允許通知',
          notifications_channels: '您被告知相關信息的渠道',
          event_type: '事件類型',
          push: '通告信息',
          recommendations: '建議',
          field_notifications: '您將接受每個地塊的哪些通知',
          none: '沒有',
          notifications: '通知',
          notifications_phase: '選擇各個地塊接受通知的類型和通道',
          notifications_mobile_phase: '臺式電腦界面可以調整高級通知的設置',
          notifications_welcome_message_title: '歡迎來到新的通知界面',
          notifications_welcome_message_body:
            '通過臺式電腦及手機可以隨時獲知當前和未來您的地塊將發生的重要事件',
          alerts_tooltip: '嚴重事件發生的預警，用以保護您的作物',
          alerts_message_continuous_irrigation_notification_center_title:
            '地塊fieldX-連續灌溉警報',
          alerts_message_continuous_irrigation_notification_center_body:
            '我們監測到該地塊已連續灌溉2天。為保護您的作物和資源，我們强烈建議您馬上查找過度灌溉的原因',
          alerts_message_continuous_irrigation_push_title:
            '地塊fieldX-連續灌溉警報',
          alerts_message_continuous_irrigation_push_body:
            '使用應用程序獲取更詳細信息',
          alerts_message_continuous_irrigation_email_subject:
            'Ndrip 系統報警 - 連續灌溉 -地塊fieldX',
          alerts_message_continuous_irrigation_email_body:
            '我們監測到該地塊已連續灌溉2天。為保護您的作物和資源，我們强烈建議您馬上查找過度灌溉的原因',
          all_fields: '所有地塊',
          current_field: '目前地塊',
          alerts_message_long_dry_period_notification_center_title:
            '長時間沒有灌溉-建議地塊fieldX進行灌溉',
          alerts_message_long_dry_period_notification_center_body:
            '地塊fieldX已10天沒有灌溉，乾旱水平升高。為保護您的作物，我們建議您根據Ndrip 的建議進行灌溉',
          alerts_message_long_dry_period_push_title:
            '長時間沒有灌溉-建議地塊fieldX進行灌溉',
          alerts_message_long_dry_period_push_body:
            '使用應用程序獲取更詳細信息',
          alerts_message_long_dry_period_email_subject:
            'Ndrip系統報警 - 地塊fieldX長時間未灌溉，建議灌溉地塊fieldX',
          alerts_message_long_dry_period_email_body:
            '地塊fieldX已10天沒有灌溉，乾旱水平升高。為保護您的作物，我們建議您根據Ndrip 的建議進行灌溉',
          alerts_message_sensor_extreme_dry_conditions_notification_center_title:
            '該地塊探測到極端乾旱狀態 -數據來自探頭sensorX',
          alerts_message_sensor_extreme_dry_conditions_notification_center_body:
            '探頭sensorX的數據顯示地塊處於極度乾燥狀態。為保證獲得準確的數據，保護您的作物，我們建議您對灌溉計劃，系統和探頭進行檢查。',
          alerts_message_sensor_extreme_dry_conditions_push_title:
            '該地塊探測到極端乾旱狀態 -數據來自探頭sensorX',
          alerts_message_sensor_extreme_dry_conditions_push_body:
            '使用應用程序獲取更詳細信息',
          alerts_message_sensor_extreme_dry_conditions_email_subject:
            'Ndrip系統報警 - 地塊fieldX探測到極端乾旱狀態 -數據來自探頭sensorX',
          alerts_message_sensor_extreme_dry_conditions_email_body:
            '探頭X的數據顯示地塊處於極度乾燥狀態。為保證獲得準確的數據，保護您的作物，我們建議您對灌溉計劃，系統和探頭進行檢查。',
          alerts_message_drying_trend_notification_center_title:
            '您的地塊監測到土壤乾燥的趨勢',
          alerts_message_drying_trend_notification_center_body:
            '我們監測到地塊fieldX 的土壤水勢出現降低的趨勢，低於建議的水平。為保持作物的健康生長，請檢查您現有的灌溉計劃，並采用Ndrip的建議。',
          alerts_message_dry_trend_push_title: '地塊fieldX監測到土壤乾燥的趨勢',
          alerts_message_dry_trend_push_body: '使用應用程序獲取更詳細信息',
          alerts_message_dry_trend_email_subject:
            'Ndrip系統報警 - 地塊fieldX監測到乾燥趨勢',
          alerts_message_dry_trend_email_body:
            '我們監測到地塊fieldX 的土壤水勢出現降低的趨勢，低於建議的水平。為保持作物的健康生長，請檢查您現有的灌溉計劃，並采用Ndrip的建議。',
          alerts_message_wetting_trend_notification_center_title:
            '您的地塊監測到土壤濕潤的趨勢',
          alerts_message_wetting_trend_notification_center_body:
            '我們監測到地塊fieldX 的土壤水勢出現升高的趨勢，高於建議的水平。為保持作物的健康生長，請檢查您現有的灌溉計劃，並采用Ndrip的建議。',
          alerts_message_wet_trend_push_title: '地塊X監測到土壤濕潤的趨勢',
          alerts_message_wet_trend_push_body: '使用應用程序獲取更詳細信息',
          alerts_message_wet_trend_email_subject:
            'Ndrip系統報警 - 地塊fieldX監測到濕潤趨勢',
          alerts_message_wet_trend_email_body:
            '我們監測到地塊fieldX 的土壤水勢出現升高的趨勢，高於建議的水平。為保持作物的健康生長，請檢查您現有的灌溉計劃，並采用Ndrip的建議。',
          alerts_message_excessive_heat_notification_center_title: '空氣溫度高',
          alerts_message_excessive_heat_notification_center_body:
            '地塊 fieldX 在未來幾天預計出現高溫天氣。為保護您的作物，建議參照我們提供的更新的灌溉方案',
          alerts_message_excessive_heat_push_title: '地塊fieldX高溫天氣',
          alerts_message_excessive_heat_push_body: '使用應用程序獲取更詳細信息',
          alerts_message_excessive_heat_email_subject:
            '  Ndrip系統報警 - 地塊fieldX高溫',
          alerts_message_excessive_heat_email_body:
            '地塊 fieldX 在未來幾天預計出現高溫天氣。為保護您的作物，建議參照我們提供的更新的灌溉方案',
          alerts_message_extreme_cold_notification_center_title: '低溫天氣',
          alerts_message_extreme_cold_notification_center_body:
            '地塊 fieldX 在未來幾天預計出現低溫天氣。為保護您的作物，建議參照我們提供的更新的灌溉方案',
          alerts_message_extreme_cold_push_title: '地塊fieldX低溫天氣',
          alerts_message_extreme_cold_push_body: '使用應用程序獲取更詳細信息',
          alerts_message_extreme_cold_email_subject:
            '  Ndrip系統報警 - 地塊fieldX低溫',
          alerts_message_extreme_cold_email_body:
            '地塊 fieldX 在未來幾天預計出現低溫天氣。為保護您的作物，建議參照我們提供的更新的灌溉方案',
          alerts_message_ndvi_notification_center_title: '植物生長活動水平降低',
          alerts_message_ndvi_notification_center_body:
            '我們近期NDVI影像數據顯示地塊fieldX中的植物生長活動水平降低。為保護您的作物，請檢查您當前的灌溉計劃',
          alerts_message_ndvi_notification_center_push:
            '依據NDVI影像數據顯示植物生長活動水平降低',
          alerts_message_ndvi_notification_center_email_subject:
            'Ndrip系統報警 - 依據NDVI影像數據顯示植物生長活動水平降低',
          alerts_message_ndvi_notification_center_email_body:
            '我們近期NDVI影像數據顯示地塊fieldX中的植物生長活動水平降低。為保護您的作物，請檢查您當前的灌溉計劃',
          alerts_message_ndvi_heterogeneity_notification_center_title:
            '地塊監測出高NDVI異質性',
          alerts_message_ndvi_heterogeneity_notification_center_body:
            '我們近期NDVI影像數據顯示地塊作物異質性升高。這也許顯示地塊中有些區域處於缺水狀態。為保護您的作物，請檢查您當前的灌溉計劃',
          transmitter_test_mobile_account_page_perfom_tests: '進行測試',
          transmitter_test_mobile_account_page_history_log: '歷史記錄',
          transmitter_test_mobile__history_log_test_results: '全部測試結果',
          transmitter_test_mobile__history_log_test_time: '測試時間',
          transmitter_test_mobile_test_page_coordiantes: '坐標',
          transmitter_test_mobile_test_page_map_navigation: '導航至探頭',
          transmitter_test_mobile_account_page_support: '支持',
          transmitter_test_mobile_auto_test: '自動運行測試',
          no_notifications: '目前沒有通知',
          edit_field_irrigation_timeframe_source_soil: '土壤類型限制',
          edit_field_irrigation_timeframe_source_irrigation_rate: '灌溉量',
          edit_field_irrigation_timeframe_source_user: '由用戶定義',
          edit_field_irrigation_timeframe_tooltip: '原始限制條件',
          edit_field_irrigation_timeframe_tooltip_soil:
            '原始限制：X毫米（來源：土壤類型）',
          edit_field_irrigation_timeframe_tooltip_irrigation_rate:
            '原始限制：X毫米（來源：灌溉量）',
          planner_days_shorts_sun: '周日',
          planner_days_shorts_mon: '周一',
          planner_days_shorts_tue: '周二',
          planner_days_shorts_wed: '周三',
          planner_days_shorts_thu: '周四',
          planner_days_shorts_fri: '周五',
          planner_days_shorts_sat: '周六',
          shorts_months_january: '一月',
          shorts_months_february: '二月',
          shorts_months_march: '三月',
          shorts_months_april: '四月',
          shorts_months_may: '五月',
          shorts_months_june: '六月',
          shorts_months_july: '七月',
          shorts_months_august: '八月',
          shorts_months_september: '九月',
          shorts_months_october: '十月',
          shorts_months_november: '十一月',
          shorts_months_december: '十二月',
          dont_show_again: '不要重複顯示',
          notifications_settings: '通知設置',
          read_more: '閲讀更多',
          show_less: '顯示更少',
          distribution_menu_select: '選擇分佈區域',
          farming_unit_menu_select: '選擇地塊',
          field_menu_select: '選擇地塊',
          recent_field: '近期查看過的地塊',
          discharge_test: '流量排放測試',
          planner_season_start: '季度開始',
          planner_season_end: '季度結束',
          planner_growth_phase_initial: '開始',
          planner_growth_phase_development: '進展',
          planner_growth_phase_middle: '中期',
          planner_growth_phase_late: '晚期',
          planner_export_button: '輸出',
          planner_add_event_button: '添加事件',
          planner_water_treatment: '水處理',
          planner_add_button: '增加',
          planner_start_time: '開始時間',
          edit_field_irrigation_start_date: '灌溉開始日期',
          edit_field_pre_season_irrigation_start_date: '',
          edit_field_sow_date_hint:
            '播種日期是Kc表格中作物生長的開始。對多年生作物，這個日期代表新葉的出現。',
          edit_field_planting_year: '種植年份',
          planner_download_button: '下載',
          planner_export_message: '您的文件下載成功',
          planner_export_error_message: '未成功，請重新嘗試',
          planner_export_file_name: '季度計劃表',
          event_delete_unsuccessfully: '該事件目前無法被刪除，請稍後再試',
          event_delete_successfully: '事件已成功刪除',
          clear: '清除',
          discharge_no_tests_committed: '沒有進行過測試',
          average: '平均',
          seconds: '秒',
          discharge_reported_successfully: '測試已成功完成報告',
          discharge_reported_unsuccessfully:
            '測試目前無法完成報告。請隨後再試。',
          delete_item_title: '本項將被刪除',
          delete_item_text: '本操作無法恢復，數據將丟失',
          discharge_result_exceeding_hint: '測試結果與排放趨勢不符',
          edit_field_calculated_irrigation_rate: '',
          edit_field_measured_irrigation_rate: '測量的灌溉量',
          edit_field_representative_input_subtitle: '',
          edit_field_tank_type: '水箱類型',
          edit_field_representative_input: '',
          edit_field_representative_input_checkbox:
            '采用測量取得的灌溉量。這將影響灌溉計劃。',
          edit_field_custome_irrigation_rate: '自行設置灌溉量',
          edit_field_archive_field_message: '您確認將該地塊歸檔？',
          alerts_message_dryland_long_dry_period_notification_center_title:
            '監測到較長乾旱狀態',
          alerts_message_dryland_long_dry_period_notification_center_body:
            '地塊fieldX至少10天沒有水，造成缺水狀態',
          alerts_message_dryland_long_dry_period_push_notification_title:
            '監測到較長乾旱狀態',
          alerts_message_dryland_long_dry_period_email_subject:
            'Ndrip系統報警 - 監測到較長乾燥狀態',
          alerts_message_dryland_long_dry_period_email_body:
            '地塊fieldX至少10天沒有水，造成缺水狀態',
          alerts_message_dryland_sensor_exterme_dry_notification_center_title:
            '該地塊監測到非常乾旱狀態 - 數據來自探頭sensorX',
          alerts_message_dryland_sensor_exterme_dry_notification_center_body:
            '探頭sensorX數據顯示該地塊處於極度乾旱狀態',
          alerts_message_dryland_sensor_exterme_dry_push_notification_title:
            '該地塊監測到非常乾旱狀態 - 數據來自探頭sensorX',
          alerts_message_dryland_sensor_exterme_dry_push_email_subject:
            'Ndrip系統報警 - 監測到地塊X處於極度乾燥狀態 - 數據來自探頭sensorX',
          alerts_message_dryland_sensor_exterme_dry_push_email_body:
            '探頭sensorX數據顯示該地塊處於極度乾旱狀態',
          alerts_message_dryland_drying_trend_notification_center_title:
            '您的地塊監測到乾燥的趨勢',
          alerts_message_dryland_drying_trend_notification_center_body:
            '我們注意到地塊fieldX的土壤水勢降低，土壤乾旱的趨勢',
          alerts_message_dryland_drying_trend_push_notification_title:
            '地塊fieldX探測到乾旱趨勢',
          alerts_message_dryland_drying_trend_email_subject:
            'Ndrip系統報警-地塊 fieldX探測到乾旱趨勢',
          alerts_message_dryland_drying_trend_email_body:
            '我們注意到地塊X的土壤水勢降低，土壤乾旱的趨勢',
          alerts_message_dryland_wetting_trend_notification_center_title:
            '您的地塊探測出濕潤趨勢',
          alerts_message_dryland_wetting_trend_notification_center_body:
            '我們探測到地塊X的土壤水勢升高',
          alerts_message_dryland_wetting_trend_push_notification_title:
            '地塊fieldX 探測到濕潤趨勢',
          alerts_message_dryland_wetting_trend_email_subject:
            'Ndrip系統報警 - 地塊 X探測到土壤濕潤趨勢',
          alerts_message_dryland_wetting_trend_email_body:
            '我們注意到地塊fieldX土壤濕潤的趨勢',
          alerts_message_dryland_excessive_heat_notification_center_title:
            '熱浪預警',
          alerts_message_dryland_excessive_heat_notification_center_body:
            '地塊fieldX 所在區域未來幾天會有嚴重熱浪天氣',
          alerts_message_dryland_excessive_heat_push_notification_title:
            '地塊fieldX 所在區域熱浪天氣預警',
          alerts_message_dryland_excessive_heat_email_subject:
            'Ndrip系統報警 - 地塊fieldX區域熱浪天氣',
          alerts_message_dryland_excessive_heat_email_body:
            '地塊fieldX區域未來幾天預期有熱浪天氣',
          alerts_message_dryland_extreme_cold_notification_center_title:
            '寒潮預警',
          alerts_message_dryland_extreme_cold_notification_center_body:
            '地塊fieldX近期寒潮預警',
          alerts_message_dryland_extreme_cold_push_notification_title:
            '地塊fieldX冷空氣預警',
          alerts_message_dryland_extreme_cold_email_subject:
            'Ndrip系統報警 - 地塊fieldX區域寒潮天氣',
          alerts_message_dryland_extreme_cold_email_body:
            '地塊fieldX 所在區域未來幾天會有極端寒潮天氣',
          alerts_message_dryland_ndvi_notification_center_title:
            '植物生長活動水平降低',
          alerts_message_dryland_ndvi_notification_center_body:
            '我們近期NDVI影像數據顯示地塊fieldX中的植物生長活動水平顯著降低。為保護您的作物，請檢查您當前的灌溉計劃',
          alerts_message_dryland_ndvi_notification_center_push:
            'NDVI分析顯示植物生長活動水平降低',
          alerts_message_dryland_ndvi_notification_center_email_subject:
            'Ndrip系統報警 - 根據NDVI數據顯示植物生長活動水平降低',
          alerts_message_dryland_ndvi_notification_center_email_body:
            '我們近期NDVI影像數據顯示地塊fieldX中的植物生長活動水平顯著降低。為保護您的作物，請檢查您當前的灌溉計劃',
          country: '國家',
          monday_id: 'Monday ID',
          Insights_title_county: '縣',
          Insights_group_by: '分組類型',
          Insights_title_total_irrigation: '縂灌溉量',
          Insights_title_total_applied_nitorgen: '縂施肥量',
          'Insights_title_benchmark _total_Irrigation': '基礎點-縂灌溉量',
          Insights_title_current_water_saving: '目前節水量',
          timeframe_name: '時間範圍',
          Timeframe_end_of_season: '季度末',
          day: '天',
          source: '來源',
          reported_events: '報告的事件',
          wtr_reported_successfully: '水處理報告成功',
          wtr_select_type: '選擇處理類型',
          water_treatment: '水處理',
          wtr_checkbox: '包括水處理',
          kc_phases_stress_checkbox: '減少灌溉量',
          kc_phases_stress_tooltip:
            '根據以下ETc降低的百分比，開始一個計劃的作物缺水階段',
          kc_phases_crop_selection_message:
            '請注意：這個作物種類將經過一個缺水階段。請同農戶確認是否可以，還是選擇另一個作物狀態',
          kc_phases_crop_selection_message_button: '明白',
          WT_protocol_edit_field_water_properties_section_title: '水質',
          WT_protocol_edit_field_water_source: '水源',
          WT_protocol_edit_field_water_source_type_well: '水井',
          WT_protocol_edit_field_water_source_type_river: '河流',
          WT_protocol_edit_field_water_source_type_reservoir: '水庫',
          WT_protocol_edit_field_water_source_type_canal: '水渠',
          WT_protocol_edit_field_water_conveyance: '露天還是封閉',
          WT_protocol_edit_field_water_conveyance_type_open: '開',
          WT_protocol_edit_field_water_conveyance_type_closed: '関',
          WT_protocol_edit_field_water_conveyance_type_mix: '混合',
          WT_protocol_edit_field_water_analysis_section_title: '水質分析',
          WT_protocol_edit_field_water_analysis_section_subtitle:
            '見各個選項中的建議範圍',
          WT_protocol_edit_field_water_analysis_table_materials: '水中所含物質',
          WT_protocol_edit_field_water_analysis_table_score: '數值',
          WT_protocol_edit_field_water_analysis_table_low: '低',
          WT_protocol_edit_field_water_analysis_table_medium: '中',
          WT_protocol_edit_field_water_analysis_table_high: '高',
          WT_protocol_edit_field_water_analysis_table_organic: '有機物',
          WT_protocol_edit_field_water_analysis_table_calc: '鈣',
          WT_protocol_edit_field_water_analysis_table_fe: '鉄',
          WT_protocol_edit_field_organic_farming: '有機種植',
          WT_protocol_edit_field_organic_farming_yes: '是',
          WT_protocol_edit_field_organic_farming_no: '不',
          WT_protocol_edit_field_material_concentrations: '液體濃度',
          WT_protocol_edit_field_HPO_concentrations: 'HPO 濃度',
          WT_protocol_edit_field_acid_titration: '酸滴定',
          WT_timing: '時間',
          WT_timing_recommendation_after_irrigation_starts: '灌溉30分鐘后',
          WT_timing_recommendation_last_hour: '灌溉最後一個小時',
          Fert_tank_height: '化肥罐液位',
          Fert_tank_volume: '化肥罐體積',
          discharge_offline_message_title: '您現在離綫狀態，但數據已保存',
          discharge_offline_message_body: '通訊恢復后我們將自動同步',
          discharge_offline_indication_title: '離綫',
          discharge_offline_indication_message:
            '數據在通訊連接恢復后將自動同步',
          sort_by_alphabetical: '按字母順序',
          select_sorting_method: '選擇篩選方法',
          Farming_unit_entity_all_farming_units_dropdown: '所有種植單位',
          Farming_unit_entity_all: '所有',
          Farming_unit_entity_tooltip: '取消的項目可以通過編輯種植單位而改變',
          Farming_unit_entity_basic_info: '基本信息',
          Farming_unit_entity_add_new_button: '增加新選項',
          Farming_unit_entity_create_title: '創建新的種植單位',
          Farming_unit_entity_add_title: '增加種植單位',
          Save_button: '保存',
          Fert_sensor_checkbox: '應該支持水肥灌溉',
          Fert_sensor_fertlization_type: '施肥種類',
          Fert_sensor_meter_name: '計量表名稱',
          Customer_dropdown: '客戶',
          disabled_elements: '取消的項目可以通過編輯種植單位而改變',
          add_fertilizer: '施加化肥',
          fertilizer_meter_id: '肥料計量表ID',
          fertilizer_type: '化肥種類',
          basic_info: '基本信息',
          details: '細節',
          Edit_field_error_message_harvest_sow:
            '收穫日期無法設置在播種日期之前',
          Edit_field_planner_dynamic_toggle: '允許動態更新',
          Edit_field_planner_dynamic_info:
            '灌溉建議隨時會因實際灌溉的改變而改變',
          KC_phases_title_growth_phase: '生長階段',
          KC_phases_title: '當前生長階段',
          KC_phases_title_pre_season: '季度前',
          KC_phases_title_in_between_seasons: '季度閒',
          KC_phases_season_end: '季度終止于',
          KC_phases_season_end_by_gdd: '種植季與xx GDDs結束',
          KC_phases_season_ended: '種植季結束',
          KC_phases_phase_tooltip_start: '開始',
          KC_phases_phase_tooltip_end: '結束',
          KC_phases_button_save: '保存',
          KC_phases_button_reset: '重新設置',
          KC_phases_stress_inidication: '受控的降低水量的灌溉',
          KC_phases_button_edit: '編輯',
          KC_phases_fast_flow: '設置當前階段',
          'KC_phases_now_indication ': '現在',
          KC_phases_estimated_short: '估計值',
          KC_phases_estimated_season_end: '估計的季度結束日期',
          KC_phases_edit_mode_default_title: '選擇一個時間段調整其時長',
          KC_phases_edit_mode_title: '編輯（   ）時間段',
          Field_details_days_in_season: '種植季内天數',
          Irrigation_system_seepage_type: '滲漏',
          kc_phases_error_message: '輸入的數據與現有的時間相關的輸入衝突',
        },
      },
      es: {
        translations: {
          edit_field_pre_season_irrigation_start_date:
            'Fecha de inicio del riego pre-temporada',
          login_registration_confirmation_message:
            'Por favor, confirme su registro haciendo clic en el enlace de confirmación recibido por correo eletronico en tu cuenta',
          login_registration_after_confirmation_message:
            'Después de la confirmación, vaya a',
          login_registration_after_confirmation_message_2: 'e iniciar sesión',
          login_enter_your_email: 'Ingrese tu correo eletrónico',
          login_enter_your_password: 'Ingrese tu contraseña',
          login_enter_a_valid_email:
            'Ingrese una cuenta de correo eletrónico válida',
          login_enter_a_valid_confirmation_code:
            'Ingrese un código de confimación válido',
          login_forgot_password: '¿Se te olvidó tu contraseña?',
          login_enter_verification_code_message:
            'Ingrese el código de verificación que recibió en su cuenta de correo eletrónico',
          login_enter_confirmation_code_message:
            'Ingrese el código de confirmación que recibió en su cuenta de correo eletrónico',
          login_enter_confirmation_code: 'Ingrese el código de confirmación',
          confirmation_code: 'Código de confirmación',
          login_new_password: 'Nueva contraseña',
          login_enter_a_valid_password: 'Ingrese una contraseña válida',
          login_dont_have_account_message: '¿No tienes cuenta?',
          login_sign_up: 'Regístrate',
          login_enter_first_name: 'Ingrese tu primer nombre',
          login_enter_last_name: 'Ingrese tu apellido',
          login_enter_phone_number: 'Ingrese tu número telefónico',
          login_password_limitation_message:
            'La contraseña debe tener al menos 8 caracteres con al menos una letra mayúscula y minúscula.',
          account_first_name_invalid_data_message:
            'Ingrese un primer nombre válido',
          account_last_name_invalid_data_message: 'Ingrese un apellido válido',
          account_phone_number_invalid_data_message:
            'Ingrese un número telefónico válido',
          account_email_invalid_data_message:
            'Ingrese un correo eletrónico válido',
          account_notifications: '¿Quieres recibir notificaciones?',
          loading: 'Cargando...',
          kc_developments: 'Desarrollo',
          kc_auto_generate: 'Autogenerar',
          kc_crop_type_invalid_data_message:
            'Ingrese un tipo de cultura válido',
          kc_variety: 'Variedad',
          kc_variety_invalid_data_message: 'Ingrese una variedade válida',
          kc_feature: 'Característica',
          kc_feature_invalid_data_message: 'Ingrese una característica válida',
          kc_sow_date: 'Fecha de siembra',
          kc_sow_date_invalid_data_message:
            'Ingrese una fecha de siembra válida',
          kc_kc_table: 'Tabela de Kc',
          kc_by_month: 'Por mes',
          kc_by_growth_phase: 'Por fase de crecimiento',
          kc_add_development_phase: 'Agregar una fase de desarrollo',
          kc_phase_title_placeholder: 'Inserte el título de la fase',
          kc_first_phase_starts: 'La primera fase comienza el',
          edit_field_choose_area_message: 'Elige un área para su campo',
          edit_field_field_name: 'Nombre del campo',
          edit_field_last_recommendation: 'Última recomendación',
          edit_field_no_fields: 'No hay campos para mostrar',
          edit_field_field_name_invalid_data_message:
            'Ingrese un nombre de campo válido',
          edit_field_area_invalid_data_message:
            'Marque su área de campo usando el menú de dibujo a la izquierda',
          edit_field_time_zone_invalid_message:
            'Ingrese una zona horaria válida',
          edit_field_water_meter_sensors: 'Sensores de medición de agua',
          edit_field_installation_date: 'Fecha de instalación',
          edit_field_installation_date_invalid_message:
            'Ingrese una fecha de instalación válida',
          edit_field_contract_duration: 'Duración del contrato (años)',
          edit_field_contract_duration_invalid_message:
            'La duración del contrato debe ser mayor que 0',
          edit_field_delete_field_message:
            'Seguro que quieres eliminar el campo?',
          irrigate_command: 'Por favor, irrigue',
          get_ready_to_irrigate: 'Prepárate para irrigar',
          dont_irrigate_command: 'No es necesario irrigar',
          stress_level_very_high: 'El nivel de estrés es muy alto',
          stress_level_high: 'El nivel de estrés es alto',
          stress_level_getting_low: 'El nivel de estrés está bajando',
          stress_level_optimal: 'El nivel de estrés es óptimo',
          no_recommendation_available: 'No hay recomendación disponible',
          expiration_date: 'Fecha de expiración',
          soil_type: 'Tipo de suelo',
          crop_type: 'Tipo de cultivo',
          dashboard_irrigation_amount: 'Cantidad de irrigación',
          dashboard_my_fields: 'Mis campos',
          dashboard_delete_field: 'Eliminar',
          dashboard_copy_field_id: 'Copiar el campo',
          dashboard_edit_field: 'Editar',
          dashboard_add_field: 'Agregar campo',
          dashboard_new_field: 'Nuevo campo',
          dashboard_loading_data_message:
            'Estamos recopilando datos, debe tomar entre 6 a 8 horas.',
          management_distribution_units_management:
            'Gestión de Unidades de Distribución',
          transmitter_admin_page: 'Evaluación de transmisores',
          management_admins_only: 'Solo administradores',
          management_farming_units_management: 'Manejo de Unidades Agrícolas',
          management_delete_distribution_message:
            '¿Estás seguro de que deseas eliminar la distribución?',
          management_manage_distribution_users_title:
            'Administrar (distribución) usuarios',
          management_enter_a_valid_username:
            'Ingrese un nombre de usuario válido - ej. usuario@dominio.com',
          management_enter_a_valid_distribution_name:
            'Ingrese un nombre de distribución válido',
          management_enter_a_valid_farming_unit_name:
            'Ingrese un nombre de unidade agrícola válido',
          management_enter_a_valid_region: 'Ingrese una región válida',
          management_farming_unit_name: 'Nombre de la unidade agrícola',
          management_farming_unit_salesforce_id: 'Salesforce ID',
          management_distribution_name: 'Nombre de distribución',
          time_zone: 'Zona horaria',
          tensiometers: 'Tensiómetros',
          distribution_unit: 'Unidad de distribución',
          farming_unit: 'Unidad agrícola',
          field_amount: 'Cantidad de campos',
          region: 'Región',
          actions: 'Acciones',
          close: 'Cerrar',
          user: 'Usuario',
          role: 'Papel',
          admin: 'Administrador',
          viewer: 'Espectador',
          account: 'Cuenta',
          logout: 'Cerrar sesión',
          first_name: 'Primer nombre',
          last_name: 'Apellido',
          phone_number: 'Número de teléfono',
          email: 'Correo eletrónico',
          units: 'Unidades',
          area: 'Zona',
          gdd: 'GDD',
          et0: 'ETo',
          leached: 'Lixiviado',
          plant: 'Planta',
          soil: 'Suelo',
          temperature: 'Temperatura',
          weight: 'Peso',
          pressure: 'Presión',
          length: 'Extensión',
          volume: 'Volumen',
          estimated_nitrogen_values: 'Valores estimados de nitrógeno',
          temperature_celsius: 'Celsius',
          temperature_fahrenheit: 'Fahrenheit',
          weight_kg: 'Kg',
          weight_lb: 'lb',
          weight_tonne: 'Tonelada',
          weight_short_ton: 'Tonelada corta',
          pressure_kpa: 'kPa',
          pressure_psi: 'PSI',
          pressure_cbar: 'CBar',
          pressure_mm: 'mm',
          pressure_pa: 'Pa',
          length_mm: 'mm',
          length_inch: 'Polegada',
          volume_cubic_metre: 'Metro cúbico',
          volume_gallon: 'Galón',
          volume_bushel: 'Bushel',
          volume_acre_foot: 'Pie por acre',
          volume_acre_inch: 'Pie por polegada',
          volume_megaliter: 'megalitro',
          phase: 'Fase',
          kc: 'Kc',
          kc_optimized: 'Kc optimizado',
          duration: 'Duración',
          days: 'Días',
          months_january: 'Janero',
          months_february: 'Febrero',
          months_march: 'Marzo',
          months_april: 'Abril',
          months_may: 'Mayo',
          months_june: 'Junio ',
          months_july: 'Julio',
          months_august: 'Agosto',
          months_september: 'Septiembre',
          months_october: 'Octubre',
          months_november: 'Noviembre',
          months_december: 'Diciembre',
          choose_date: 'Elegir fecha',
          last_day: 'Último día',
          last_week: 'Última semana',
          last_two_weeks: 'Últimas 2 semanas',
          last_month: 'Último mes',
          last_six_months: 'Últimos 6 meses',
          supporting_data: 'Datos de soporte',
          water_stress_level: 'Nivel de estrés',
          fertilization_gauge_title: 'Niveles de nitrógeno',
          map_view: 'Vista del mapa',
          serial_no: 'Número de serie',
          sensor_serial_no: 'Número de serie del sensor',
          sensor_latitude: 'Latitud del sensor',
          sensor_longitude: 'Longitud del sensor',
          water_meter_units: 'm3 por pulso',
          name: 'Nombre',
          leaf_length: 'Longitud de las hojas',
          latitude: 'Latitud',
          longitude: 'Longitud',
          create: 'Crear',
          update: 'Actualizar',
          field: 'Campo',
          cancel: 'Cancelar',
          delete: 'Borrar',
          ok: 'OK',
          confirmation: 'Confirmación',
          confirm: 'Confirmar',
          yes: 'Si',
          no: 'No',
          home: 'Inicio',
          change: 'Cambiar',
          forgot: 'Olvidó',
          password: 'Contraseña',
          login: 'Iniciar sésion',
          prefix: 'Prefijo',
          register: 'Registro',
          settings: 'Configuraciones',
          amount: 'Cantidad',
          date_and_time: 'Fecha y hora',
          report: 'Insertar',
          hours: 'Horas',
          report_irrigation: 'Insertar irrigación',
          report_nitrogen: 'Insertar nitrógeno',
          irrigation_reported_successfully: 'Irrigación informada con éxito',
          is_manure: '¿Es estiercól?',
          irrigation: 'Irrigación',
          select_sensors: 'Seleccionar sensores',
          manure: 'Estiercól',
          nitrogen: 'Nitrógeno',
          fertilization_history: 'Historial de fertilización',
          water_pressure_history: 'Historia del potencial hídrico',
          transmitter_id: 'Identificación del transmisor',
          support_ndvi: '¿Soporte NDVI?',
          i_agree_to: 'Estoy de acuerdo con',
          terms_and_conditions: 'Términos y condiciones',
          language: 'Idioma ',
          last_update: 'Última actualización',
          hours_ago: 'hace {0} horas',
          hour_ago: '1 hora antes',
          day_ago: 'Hace 1 día',
          days_ago: 'Hace {0} días',
          minute_ago: 'Hace 1 minuto',
          minutes_ago: 'Hace {0} minutos',
          login_page: 'Página de inicio',
          based_on: 'Basado en',
          test: 'Prueba',
          Sand: 'Arenoso',
          'Loamy Sand': 'Arenoso franco',
          'Sandy Loam': 'Franco arenoso',
          Loam: 'Franco',
          Silt: 'Limoso',
          'Silty Loam': 'Franco limoso',
          'Sandy Clay Loam': 'Franco arcillo arenoso',
          'Clay Loam': 'Franco arcilloso',
          'Silty Clay Loam': 'Franco arcillo limoso',
          'Sandy Clay': 'Arcillo arenoso',
          'Silty Clay': 'Arcillo limoso',
          Clay: 'Arcilloso',
          'Mix of Peat types': 'Turba',
          'Coconut fiber': 'Fibra de coco',
          'Mixed Tuff': 'Otros sustratos orgánicos',
          Alfafa: 'Alfafa',
          Artichoke: 'Alcachofa',
          Asparagus: 'Espárragos',
          Avocado: 'Palta',
          last_seen: 'Visto por última vez',
          recent_analog_values: 'Valores analógicos recientes',
          recent_rssi_values: 'Valores RSSI recientes',
          recent_battery_values: 'Valores recientes de la bateria',
          recent_c1_values: 'Valores C1 recientes',
          modem_version: 'Versión de lo modém ',
          modem_hardware: 'Hardware de lo modém ',
          iccid: 'ICCID',
          date: 'Fecha',
          value: 'Valor',
          sim_activation:
            'La SIM se está activando, espere 10 minutos antes de volver a probar',
          enter_transmitter_id: 'Ingrese da ID del transmisor',
          Cabbage: 'Repollo',
          Carrot: 'Zanahoria',
          Cauliflower: 'Coliflor',
          Celery: 'Apio',
          Lettuce: 'Lechuga',
          Onion: 'Cebolla',
          Spinach: 'Espinaca',
          Radish: 'Rábano',
          Eggplant: 'Berenjena',
          'Sweet pepper': 'Pimiento morrón',
          Tomato: 'Tomate',
          Cantaloupe: 'Melón',
          Cucumber: 'Pepino',
          'Pumpkin (Winter Squash)': 'Calabaza (Invierno)',
          Squash: 'Calabaza',
          Zucchini: 'Calabacín',
          'Sweet Melon': 'Melón dulce',
          Beet: 'Remolacha',
          Cassava: 'Mandioca',
          Potato: 'Papa',
          'Sweet potato': 'Papa dulce',
          'Sugar Beet': 'Remolacha azucarera',
          Bean: 'Frijol',
          'Faba bean, broad bean': 'Haba',
          'Green Gram': 'Garbanzo',
          Cowpea: 'Frijoles negros',
          'Peanut (Groundnut)': 'Maní',
          Lentil: 'Lenteja',
          Pea: 'Guisante',
          Soybean: 'Soja',
          Cotton: 'Algodón',
          Flax: 'Linaza',
          'Castorbean (Ricinus)': 'Castorbean (ricinus)',
          Safflower: 'Cártamo',
          Sesame: 'Sésamo',
          Sunflower: 'Girasol',
          Barley: 'Cebada',
          Oat: 'Avena',
          Wheat: 'Trigo',
          Maize: 'Maíz',
          Millet: 'Mijo',
          Sorghum: 'Sorgo',
          Rice: 'Arroz',
          Alfalfa: 'Alfafa',
          Bermuda: 'Grama Bermuda',
          Sudan: 'Hierba de Sudán',
          Sugarcane: 'Caña de azúcar',
          Banana: 'Banana',
          Pineapple: 'Piña',
          Grape: 'Uva',
          Hop: 'Lúpulo',
          Citrus: 'Citrus',
          Olive: 'Oliveira',
          Pistachio: 'Pistacho',
          Walnut: 'Nuez',
          'Cattails, Bulrush': 'Taboa',
          Mango: 'Mango',
          Grapefruit: 'Pomelo',
          Garden: 'Plantas de jardín',
          Grass: 'Gramo',
          'Genus Citrus': 'Cítricos (género)',
          Coffee: 'Café',
          Corn: 'Maíz',
          Lychee: 'Lychee',
          'Peach, Nectarine': 'Durazno, Nectarina',
          Watermelon: 'Sandía',
          'Date Palm': 'Dátil',
          Pomegranate: 'Granada',
          Cannabis: 'Cáñamo',
          Pepper: 'Pimienta',
          Blackberry: 'Mora',
          Strawberry: 'Fresa',
          Pecan: 'Nuez',
          Pear: 'Pera',
          soil_water_deficit: 'Déficit hídrico del suelo',
          n_in_organic_matter: 'N en materia orgánica',
          initial_concentrations: 'Concentraciones iniciales',
          should_automate_irrigation: 'Automatizar el riego',
          kc_harvest_date: 'Fecha de cosecha',
          kc_harvest_date_invalid_data_message:
            'Ingrese una fecha de cosecha válida',
          graphs: 'Gráficos',
          overview: 'Reporte',
          micro_version: 'Versión micro',
          login_check_your_email: 'Consulte su correo electrónico',
          create_new_password: 'Crear nueva contraseña',
          notifications: 'Notificaciones',
          version: 'Versión',
          account_settings: 'Configuracion de cuenta',
          send_reset_code: 'Enviar código de reinicio',
          forgot_password_subtitle:
            'Ingrese su correo electrónico y le enviaremos el código de restablecimiento de contraseña',
          login_title: 'Entrar',
          back: 'Volver',
          estimated: 'Estimado',
          farm: 'Unidad Agrícola',
          location: 'Localización',
          trasmitter_test: 'Prueba de transmisor',
          installation_date: 'Fecha de instalación',
          new: '¡Nuevo!',
          download_the_app: 'Descarga la aplicación móvil',
          take_the_app_to_the_field:
            'Tome N-Drip Connect mientras ingresa al campo',
          continue: 'Seguir',
          resend: 'Reenviar',
          min_temp: 'Temp Min',
          max_temp: 'Temp Max',
          etc: 'ETc',
          distribution: 'Distribución',
          'my fields': 'Mis campos',
          sensor_batch: 'Lote de sensor',
          transmission_interval: 'Intervalo de transmisión',
          meta_data: 'Metadatos',
          start: 'Comienzo',
          end: 'Fin',
          discharge: 'Descarga',
          'recent wfr samples': 'Valores recientes del medidor de agua',
          street: 'Calle',
          satellite: 'Satélite',
          topography: 'Topografía',
          ndvi: 'NDVI',
          'choose layer': 'Elegir capa',
          coordinates: '',
          planning_days: 'Días',
          planning_weeks: 'Semanas',
          planning_publish: 'Publicar',
          recent_errors_values: 'Valores de error recientes',
          sim_last_event: 'Último evento del SIM',
          stress_level_no_stress: 'Sin estrés',
          next_actions: 'Próximas acciones',
          tomorrow: 'Mañana',
          later: 'Más tarde',
          coming_soon: 'En breve',
          no_planner_events: 'No hay acciones futuras en este momento',
          history_graph: 'Gráfico histórico',
          planning_planned: 'PLANEADO',
          planning_used: 'EJECUTADO',
          event: 'Evento',
          ' hcl': 'HCl',
          hpo: 'HPO',
          note: 'Observación',
          planning_note_placeholder: 'Escribe aquí tu mensaje',
          sensors: 'sensores',
          valves: 'válvulas',
          type: 'tipo',
          'coming up': 'Subiendo',
          water_potential_graph: 'Grafico de potencial de agua',
          locate_coordinates: 'Encontrar coordenadas',
          ignore_sensor_during_calculations:
            'Ignorar el sensor en los cálculos',
          ignore_sensor_during_calculations_and_hide:
            'Ocultar e ignorar el sensor en los cálculos',
          consider_as_flow_meter: 'Considerar como medidor de flujo',
          edit: 'Editar',
          alerts: 'Alertas',
          stop_data_collection: 'Detener la recogida de datos',
          accumulated_water_input: 'Entrada de agua acumulada',
          precipitation: 'lluvia',
          petiole: 'Pecíolo',
          water_potential: 'Potencial de agua',
          minimum: 'mínimo',
          maximum: 'máximo',
          max_bigger_than_min:
            'El valor máximo debe ser mayor que el valor mínimo',
          min_smaller_than_max:
            'El valor mínimo debe ser menor que el valor máximo',
          last_year: 'Último ano',
          moderator: 'Moderador',
          designed_pressure: 'Presión estimada',
          field_size: 'Tamaño del campo',
          duplicate_field: 'duplicar el campo',
          duplicate: 'duplicar',
          offset: 'Offset',
          'Nitrogen graph': 'Gráfico de Nitrógeno',
          select_all: 'Seleccionar todo',
          select_water_input: 'seleccionar entrada de agua',
          'Planned irrigation': 'Riego planificado',
          days_from_sowing: 'Días después de la siembra',
          Field_details_days_in_season: 'Días en temporada',
          day_sunday: 'Domingo',
          day_monday: 'Lunes',
          day_tuesday: 'Martes',
          day_wednesday: 'Miércoles',
          day_thursday: 'Jueves',
          day_friday: 'Viernes',
          day_saturday: 'Sábado',
          week: 'semana',
          season: 'estación',
          today: 'hoy',
          show_archived: 'Mostrar campos archivados',
          events_list: 'Lista de eventos',
          notes: 'Notas',
          week_total: 'Total semanal',
          season_total: 'Total temporada',
          no_reported: 'Ningún evento agregado',
          report_event: 'Reportar evento',
          save: 'ahorrar',
          rain: 'lluvia',
          starting_time: 'hora de inicio',
          fertilization: 'fertilización',
          reported: 'Agregados',
          plant_height: 'Altura de planta',
          fert_tank_height: 'Nivel del tanque de fertilización',
          Fert_tank_volume: 'Volumen del tanque de fertilización',
          allow_notifications: 'Permitir notificaciones',
          notifications_channels: 'En qué canales serás notificado',
          event_type: 'Tipo de evento',
          push: 'Activar',
          recommendations: 'Recomendaciones',
          field_notifications:
            'Quais notificações você receberá para cada campo',
          none: 'Ninguno',
          notifications_phase:
            'Controlar los tipos de notificación y canales a través de los cuales seré notificado',
          alerts_tooltip:
            'Las alertas indican eventos adversos que requieren acción inmediata para proteger los cultivos',
          recent_connectivity_values: 'Valores de conectividad recientes',
          alerts_message_continuous_irrigation_notification_center_title:
            'Alerta de riego continuo - fieldX',
          alerts_message_continuous_irrigation_notification_center_body:
            'Hemos detectado riego continuo durante más de 2 días para el campo. Para proteger los cultivos, le recomendamos comprobar si el campo está siendo regado en exceso.',
          alerts_message_sensor_extreme_dry_conditions_notification_center_title:
            'Condiciones de sequía extrema detectadas en el campo - Datos recibidos del sensorX',
          alerts_message_long_dry_period_notification_center_title:
            'Largo período sin riego -  Recomendados el riego del fieldX',
          alerts_message_sensor_extreme_dry_conditions_notification_center_body:
            'Los datos recibidos por el sensorX indican una condición extremadamente seca en el campo. Para garantizar datos precisos y protección de cultivos, recomendamos inspeccionar el plan de riego, y el sistema de riego alrededor del sensor.',
          alerts_message_long_dry_period_notification_center_body:
            'El fieldX no ha recibido agua durante 10 días, lo que puede provocar estrés hídrico. Para proteger los cultivos, recomendamos regar el campo siguiendo tu plan de riego N-Drip.',
          alerts_message_drying_trend_notification_center_title:
            'Tendencia de secado detectada en su campo',
          alerts_message_drying_trend_notification_center_body:
            'Observamos una tendencia decreciente del potencial hídrico en el fieldX, por debajo del nivel recomendado. Para optimizar los cultivos, revisa y ajusta tu plan de riego actual según las recomendaciones de N-Drip.',
          alerts_message_wetting_trend_notification_center_title:
            'Tendencia de humedecimiento detectada en su campo',
          alerts_message_wetting_trend_notification_center_body:
            'Observamos una tendencia creciente del potencial hídrico en el fieldX, por encima del nivel recomendado. Para optimizar los cultivos, revisa y ajusta tu plan de riego actual según las recomendaciones de N-Drip.',
          alerts_message_excessive_heat_notification_center_title:
            'Alta temperatura del aire',
          alerts_message_excessive_heat_notification_center_body:
            'Se pronostica alta temperatura del aire para la zona del fieldX, durante los próximos días.\nPara proteger sus cultivos revisa y ajusta tu plan de riego actual según nuestras recomendaciones actualizadas.',
          alerts_message_extreme_cold_notification_center_title:
            'Alerta de ola de frio',
          alerts_message_extreme_cold_notification_center_body:
            'Se pronostica una importante ola de frio para la zona del fieldX durante los próximos días. Para proteger sus cultivos de los daños causados por las heladas, revisa y ajusta tu plan de riego actual según nuestras recomendaciones actualizadas.',
          notifications_welcome_message_title:
            'Bem-vindo ao novo painel de notificações',
          notifications_welcome_message_body:
            'Fique por dentro de eventos importantes atuais e esperados em suas lavouras no desktop e dispositivos móveis',
          alerts_message_dryland_long_dry_period_notification_center_title:
            'Largo período seco detectado',
          alerts_message_dryland_long_dry_period_notification_center_body:
            'El fieldX no ha recibido agua durante 10 días, lo que puede provocar estrés hídrico.',
          alerts_message_dryland_sensor_exterme_dry_notification_center_title:
            'Condiciones de sequía extrema detectadas en el campo - Datos recibidos del sensorX',
          alerts_message_dryland_sensor_exterme_dry_notification_center_body:
            'Los datos recibidos por el sensorX indican una condición extremadamente seca en el campo.',
          alerts_message_dryland_drying_trend_notification_center_title:
            'Tendencia de secado detectada en su campo',
          alerts_message_dryland_drying_trend_notification_center_body:
            'Observamos una tendencia decreciente del potencial hídrico en el fieldX, lo que puede provocar estrés hídrico.',
          alerts_message_dryland_wetting_trend_notification_center_title:
            'Tendencia de humedecimiento detectada en su campo',
          alerts_message_dryland_wetting_trend_notification_center_body:
            'Observamos una tendencia creciente del potencial hídrico en el fieldX, lo que puede provocar estrés hídrico.',
          alerts_message_dryland_excessive_heat_notification_center_title:
            'Alerta de ola de calor',
          alerts_message_dryland_excessive_heat_notification_center_body:
            'Se pronostica una importante ola de calor para la zona del fieldX durante los próximos días.',
          alerts_message_dryland_extreme_cold_notification_center_title:
            'Alerta de ola de frio',
          alerts_message_dryland_extreme_cold_notification_center_body:
            'Se pronostica una importante ola de frio para la zona del fieldX durante los próximos días.',
          alerts_message_dryland_ndvi_notification_center_title:
            'Reducción de la actividad vegetativa',
          alerts_message_dryland_ndvi_notification_center_body:
            'Análisis recientes de imágenes históricas del NDVI reveló una reducción significativa en el crecimiento vegetativo en el fieldX. Para optimizar la salud de su cultivo, revise sus prácticas de riego actuales.',
          no_notifications: 'Actualmente no hay notificaciones',
          all_fields: 'Todos los campos',
          current_field: 'Campo actual',
          planner_days_shorts_sun: 'Dom',
          planner_days_shorts_mon: 'Lun',
          planner_days_shorts_tue: 'Mar',
          planner_days_shorts_wed: 'Mie',
          planner_days_shorts_thu: 'Jue',
          planner_days_shorts_fri: 'Vie',
          planner_days_shorts_sat: 'Sab',
          shorts_months_january: 'Ene',
          shorts_months_february: 'Feb',
          shorts_months_march: 'Mar',
          shorts_months_april: 'Abr',
          shorts_months_may: 'Mayo',
          shorts_months_june: 'Jun',
          shorts_months_july: 'Jul',
          shorts_months_august: 'Ago',
          shorts_months_september: 'Sept',
          shorts_months_october: 'Oct',
          shorts_months_november: 'Nov',
          shorts_months_december: 'Dic',
          dont_show_again: 'No mostrar de nuevo',
          notifications_settings: 'Configuración de notificaciones',
          read_more: 'Leer más',
          show_less: 'Mostrar menos',
          distribution_menu_select: 'Seleccionar distribución',
          farming_unit_menu_select: 'Seleccionar Unidade Agrícola',
          field_menu_select: 'Seleccionar campo',
          recent_field: 'Campo reciente',
          discharge_test: 'Prueba de descarga',
          create_planner_item: 'Agregar nuevo evento',
          update_planner_item: 'Actualizar evento',
          planner_season_start: 'Inicio de temporada',
          planner_season_end: 'Fin de temporada',
          planner_growth_phase_initial: 'Inicial',
          planner_growth_phase_development: 'Desarrollo',
          planner_growth_phase_middle: 'Medio',
          planner_growth_phase_late: 'Final',
          planner_export_button: 'Exportar',
          planner_add_event_button: 'Agregar evento',
          planner_water_treatment: 'Tratamiento de agua',
          planner_add_button: 'Agregar',
          planner_start_time: 'Hora de inicio',
          dashboard_move_to_archive: 'Mover al archivo',
          dashboard_unarchived: 'Desarchivado',
          Insights_title_county: 'Condado',
          Insights_group_by: 'Agrupado por',
          edit_field_measured_irrigation_rate: 'Tasa de riego medida',
          edit_field_tank_type: 'Tipo del tanque de agua',
          edit_field_representative_input_checkbox:
            'Utilice los valores de tasa de riego medidos quando estén disponibles. Esto afectará la planificación del riego.',
          edit_field_custom_irrigation_rate: 'Personalizar tasa de riego',
          wtr_reported_successfully: 'Tratamiento de agua reportado con éxito',
          kc_phases_stress_checkbox: 'Aplicar estrés',
          kc_phases_stress_tooltip:
            'Iniciar un periodo planificado de estrés hídrico según el siguiente porcentaje de reducción de ETc',
          edit_field_irrigation_timeframe_source_soil:
            'limitación por tipo de suelo',
          edit_field_irrigation_timeframe_source_irrigation_rate:
            'Tasa de riego',
          edit_field_irrigation_timeframe_source_user:
            'definido por el usuario',
          edit_field_irrigation_timeframe_tooltip: 'Limitación original:',
          source: 'Origen',
          discharge_result_exceeding_hint:
            'El resultado de la prueba se desvia de la curva de tendencia de descarga',
          Timeframe_end_of_season: 'Fin de temporada',
          Farming_unit_entity_basic_info: 'Información básica',
          'Farming_unit_entity_add_new ': 'Agregar nuevo',
          Farming_unit_entity_add_new_button: 'Agregar nuevo',
          WT_protocol_edit_field_water_properties_section_title:
            'Propiedades del agua',
          WT_protocol_edit_field_water_source: 'Fuente de agua',
          WT_protocol_edit_field_water_conveyance: 'Transporte',
          WT_protocol_edit_field_water_analysis_section_title:
            'Análisis de agua',
          WT_protocol_edit_field_water_analysis_section_subtitle:
            'Ver rangos de recomendación en cada celda',
          WT_protocol_edit_field_water_analysis_table_materials: 'Materiales',
          WT_protocol_edit_field_water_analysis_table_score: 'Puntuación',
          WT_protocol_edit_field_water_analysis_table_low: 'Bajo',
          WT_protocol_edit_field_water_analysis_table_medium: 'Promedio',
          WT_protocol_edit_field_water_analysis_table_high: 'Alto',
          WT_protocol_edit_field_water_analysis_table_organic: 'Orgánico',
          WT_protocol_edit_field_water_analysis_table_calc: 'Cálculo',
          WT_protocol_edit_field_water_analysis_table_fe: 'Hierro',
          WT_protocol_edit_field_material_concentrations:
            'Concentración de materiales',
          WT_protocol_edit_field_acid_titration: 'Titulación ácida',
          Farming_unit_entity_tooltip:
            'Los elementos deshabilitados se pueden editar a través de la unidad agrícola',
          WT_timing_recommendation_after_irrigation_starts:
            'Después de 30min de riego',
          WT_timing_recommendation_last_hour: 'Última hora de riego',
          fertilizer_meter_id: ' Identificación del medidor de fertilizantes',
          add_fertilizer: 'Agregar fertilizante',
          Farming_unit_entity_all: 'Todas',
          basic_info: 'Informações básicas',
          allow_dynamic_updates: 'Permitir actualizaciones dinámicas',
          define_allow_dynamic_updates:
            'Las recomendaciones se verán afectadas por los cambios de riego en cualquier momento.',
          KC_phases_title: 'Fase de crecimiento',
          KC_phases_title_pre_season: 'Pretemporada',
          KC_phases_title_in_between_seasons: 'Entre temporadas',
          KC_phases_season_end: 'Fin de temporada',
          KC_phases_phase_tooltip_start: 'Comienzo',
          KC_phases_phase_tooltip_end: 'Fin',
          KC_phases_button_save: 'Ahorrar',
          KC_phases_button_reset: 'Reiniciar',
          KC_phases_stress_inidication: 'Riego deficitatio controlado',
          KC_phases_button_edit: 'Editar',
          KC_phases_fast_flow: 'Configurar fase actual',
          KC_phases_now_indication: 'Ahora',
          KC_phases_estimated_short: 'Est.',
          KC_phases_estimated_season_end: 'Probable fin de la temporada',
          KC_phases_edit_mode_default_title:
            'Haga clic en la fase para ajustar su duración',
          kc_phases_error_message:
            'El valor ingresado está en conflito con otros valores',
        },
      },
      hin: {
        translations: {},
      },

      ger: {
        translations: {},
      },
      fre: {
        translations: {},
      },
    },
    fallbackLng: 'en',
    debug: false,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
    },

    react: {
      wait: true,
    },
  });
