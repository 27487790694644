var React = require('react');
var { useState, useEffect, useCallback } = require('react');
var { connect } = require('react-redux');
var { withTranslation } = require('react-i18next');
var Sensor = require('Sensor');
var addNewIcon = require('images/icons/edit_field/icon_plus.svg');
var locationManager = require('locationManager');
var EditSensor = require('EditSensor');
var GeneralClasses = require('GeneralClasses');
var { useTranslation } = require('react-i18next');

function SensorsWrapper(props) {
  const [stateSensors, setStateSensors] = useState({});
  const [addNewFieldClicked, setAddNewFieldClicked] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false); // for disable button on click
  const [sensorEditIndex, setSensorEditIndex] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (!props.current_object?.sensors) return;
    const sensors = { ...props.current_object?.sensors };
    if (props.current_object.sensors) {
      if (props.farmingUnitSensors) {
        for (let sensorId in sensors) {
          if (sensors[sensorId].manufacturer === 'tal-gil') {
            const matchingFarmingUnitSensor = Object.values(
              props.farmingUnitSensors,
            ).find(
              (farmingUnitSensor) =>
                farmingUnitSensor.transmitter_id ===
                sensors[sensorId].transmitter_id,
            );

            if (matchingFarmingUnitSensor) {
              sensors[sensorId].fert_meters =
                matchingFarmingUnitSensor.fert_meters;
              sensors[sensorId].farming_unit_serial_number =
                matchingFarmingUnitSensor.id;
              sensors[sensorId].name = matchingFarmingUnitSensor.name;
              sensors[sensorId].coordinates =
                matchingFarmingUnitSensor.coordinates;
            }
          }
        }
      }

      setStateSensors(sensors);
    }
  }, [props.current_object.sensors]);

  useEffect(() => {
    return () => {
      // clean state when moving to another fields , update modal is one
      setButtonClicked(false);
      setSensorEditIndex('');
      setAddNewFieldClicked(false);
    };
  }, [props]);

  // functions

  const locateSensor = (sensorId) => {
    locationManager
      .get_user_location()
      .then(function (res) {
        let lat = res.latitude.toString().substring(0, 10);
        let long = res.longitude.toString().substring(0, 10);

        if (sensorId) {
          const newSensors = { ...stateSensors };
          let currentSensor = newSensors[sensorId];
          currentSensor['coordinates']['latitude'] = lat;
          currentSensor['coordinates']['longitude'] = long;
          setStateSensors(newSensors);
          props.onSensorsChange(newSensors);
        } else {
          // refs.sensor_id_latitude.value = lat;
          // sensor_id_longitude.value = long;
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const changeSensorIgnoreInCalculations = (sensorId, value) => {
    if (sensorId) {
      let currentSensors = { ...stateSensors };
      currentSensors[sensorId]['ignore_in_calculations'] = value;
      currentSensors[sensorId]['settings']['ignore_in_calculations'] = value;
      setStateSensors(currentSensors);
      props.onSensorsChange(currentSensors);
    }
  };

  const changeSensorPausedState = (sensorId, value) => {
    if (sensorId) {
      const newSensors = { ...stateSensors };
      let currentSensor = newSensors[sensorId];
      currentSensor['paused'] = value;
      currentSensor['settings']['paused'] = value;
      setStateSensors(newSensors);
      props.onSensorsChange(newSensors);
    }
  };

  const changePiezometerConsiderAsWFR = (sensorId, value) => {
    if (sensorId) {
      const newSensors = { ...stateSensors };
      let currentSensor = newSensors[sensorId];
      currentSensor['should_consider_as_wfr'] = value;
      setStateSensors(newSensors);
      props.onSensorsChange(stateSensors);
    }
  };

  const removeSensor = (sensor_id) => {
    if (sensor_id) {
      const newSensors = { ...stateSensors };
      if (sensor_id in newSensors) {
        delete newSensors[sensor_id];
        setStateSensors(newSensors);
      }
      props.onSensorsChange(newSensors);
    }
  };

  const editSensor = useCallback(
    (
      transmitter_id,
      latitude,
      longitude,
      m3_per_pulse,
      valves,
      type,
      name,
      offset,
      fert_meters,
      installation_depth,
    ) => {
      const newSensors = { ...stateSensors };
      let tmp_sensor = newSensors[sensorEditIndex];
      tmp_sensor['transmitter_id'] = transmitter_id;
      tmp_sensor['coordinates']['latitude'] = latitude;
      tmp_sensor['coordinates']['longitude'] = longitude;
      tmp_sensor['name'] = name;
      if (fert_meters) {
        tmp_sensor['fert_meters'] = fert_meters;
      }
      if (valves?.length) {
        tmp_sensor['valves'] = valves;
      } else if (m3_per_pulse) {
        tmp_sensor['m3_per_pulse'] = m3_per_pulse;
      } else if (offset) {
        tmp_sensor['offset'] = offset;
      }
      if (installation_depth) {
        tmp_sensor['installation_depth'] = installation_depth;
      }
      setStateSensors(newSensors);
      setButtonClicked(false);
      setSensorEditIndex('');
      props.onSensorsChange(newSensors);
    },
    [sensorEditIndex, stateSensors],
  );

  const addSensor = (
    transmitter_id,
    latitude,
    longitude,
    m3_per_pulse,
    valves,
    value,
    name,
    offset,
    fert_meters,
    installation_depth,
  ) => {
    if (transmitter_id) {
      var tmp_sensors = { ...stateSensors };
      var newSensor = undefined;
      let tmp_sensor_coordinates =
        !latitude || !longitude
          ? {}
          : { latitude: latitude, longitude: longitude };
      let tmp_m3_per_pulse = m3_per_pulse || '';
      let tempSensorId = 'tmp_' + transmitter_id;
      let tmp_valves = valves || [];
      let tmp_name = name || '';
      let tmp_offset = offset || 0;
      // add programs argument in future work
      let { type, subType } = value;
      let tmp_programs = [];
      // Tensiometer
      if (type === 'wp') {
        newSensor = new GeneralClasses.WPSensor(
          {
            coordinates: tmp_sensor_coordinates,
            name: tmp_name,
            transmitter_id: transmitter_id,
            settings: { ignore_in_calculations: false, paused: false },
          },
          tempSensorId,
        );
      } else if (type === 'tal-gil') {
        // check full fertilization meters
        newSensor = new GeneralClasses.WFRSensorTalgil(
          {
            coordinates: tmp_sensor_coordinates,
            name: tmp_name,
            transmitter_id: transmitter_id,
            settings: { ignore_in_calculations: false, paused: false },
            valves: tmp_valves,
            manufacturer: type,
            programs: tmp_programs,
            fert_meters: fert_meters,
          },
          tempSensorId,
        );
      } else if (type === 'motorola') {
        newSensor = new GeneralClasses.WFRSensorMotorola(
          {
            coordinates: tmp_sensor_coordinates,
            name: tmp_name,
            transmitter_id: transmitter_id,
            settings: { ignore_in_calculations: false, paused: false },
            valves: tmp_valves,
            manufacturer: type,
            programs: tmp_programs,
          },
          tempSensorId,
        );
      } else if (type === 'galcon') {
        newSensor = new GeneralClasses.WFRSensorGalcon(
          {
            coordinates: tmp_sensor_coordinates,
            name: tmp_name,
            transmitter_id: transmitter_id,
            settings: { ignore_in_calculations: false, paused: false },
            valves: tmp_valves,
            manufacturer: type,
            programs: tmp_programs,
          },
          tempSensorId,
        );
      } else if (type === 'aquastride') {
        newSensor = new GeneralClasses.WFRSensorAquastride(
          {
            coordinates: tmp_sensor_coordinates,
            name: tmp_name,
            transmitter_id: transmitter_id,
            settings: { ignore_in_calculations: false, paused: false },
            valves: tmp_valves,
            manufacturer: type,
            programs: tmp_programs,
          },
          tempSensorId,
        );
      } else if (type === 'ndrip wm') {
        newSensor = new GeneralClasses.WFRSensorNDrip(
          {
            coordinates: tmp_sensor_coordinates,
            name: tmp_name,
            transmitter_id: transmitter_id,
            settings: { ignore_in_calculations: false, paused: false },
            valves: tmp_valves,
            manufacturer: 'ndrip',
            sub_type: subType,
            programs: tmp_programs,
          },
          tempSensorId,
        );
      } else if (type === 'pulse') {
        newSensor = new GeneralClasses.WFRSensorNDripPulse(
          {
            coordinates: tmp_sensor_coordinates,
            m3_per_pulse: tmp_m3_per_pulse,
            name: tmp_name,
            transmitter_id: transmitter_id,
            settings: { ignore_in_calculations: false, paused: false },
            valves: tmp_valves,
            manufacturer: 'ndrip',
          },
          tempSensorId,
        );
      } else if (type === 'piezometer') {
        let should_consider_as_wfr = true;
        if (
          Object.values(tmp_sensors)?.some((sensor) => {
            return (
              (sensor.type === 'piezometer' &&
                sensor?.should_consider_as_wfr) ||
              (sensor?.type === 'wfr' && sensor?.manufacturer === 'tal-gil')
            );
          })
        ) {
          should_consider_as_wfr = false;
        }
        newSensor = new GeneralClasses.NDripPiezometer(
          {
            coordinates: tmp_sensor_coordinates,
            name: tmp_name,
            transmitter_id: transmitter_id,
            should_consider_as_wfr: should_consider_as_wfr,
            settings: { ignore_in_calculations: false, paused: false },
            valves: tmp_valves,
            offset: tmp_offset,
            manufacturer: 'ndrip',
          },
          tempSensorId,
        );
      } else if (type === 'soil_temp') {
        newSensor = new GeneralClasses.SoilTempSensor(
          {
            coordinates: tmp_sensor_coordinates,
            name: tmp_name,
            transmitter_id: transmitter_id,
            settings: { ignore_in_calculations: false, paused: false },
            manufacturer: 'ndrip',
            installation_depth: installation_depth,
          },
          tempSensorId,
        );
      }

      tmp_sensors[tempSensorId] = newSensor;

      setStateSensors(tmp_sensors);

      props.onSensorsChange(tmp_sensors);
      addNewFieldHandler();
    }
  };

  const addNewFieldHandler = () => {
    if (sensorEditIndex.length) {
      setSensorEditIndex('');
    }
    setAddNewFieldClicked(!addNewFieldClicked);
    setButtonClicked(!buttonClicked);
    setSensorEditIndex('');
  };

  const CancelEditFieldHandler = useCallback(() => {
    setButtonClicked(!buttonClicked);
    setSensorEditIndex('');
  }, [buttonClicked]);

  const editHandler = (id) => {
    if (addNewFieldClicked) {
      setAddNewFieldClicked(!addNewFieldClicked);
    }
    setSensorEditIndex(id);
    setButtonClicked(true);
  };

  const sensorsElements = Object.values(stateSensors).map((sensorProps) => {
    return sensorEditIndex !== sensorProps.id ? (
      <Sensor
        key={sensorProps.id}
        {...sensorProps}
        onEdit={() => editHandler(sensorProps.id)}
        onLocate={() => locateSensor(sensorProps.id)}
        onIgnore={() =>
          changeSensorIgnoreInCalculations(
            sensorProps.id,
            !sensorProps.ignore_in_calculations,
          )
        }
        onPaused={() =>
          changeSensorPausedState(sensorProps.id, !sensorProps.paused)
        }
        onDelete={() => removeSensor(sensorProps.id)}
        onConsiderAsWFR={() =>
          changePiezometerConsiderAsWFR(
            sensorProps.id,
            !sensorProps.should_consider_as_wfr,
          )
        }
      />
    ) : (
      <EditSensor
        existingFieldDisableType={true}
        key={sensorProps.id}
        {...sensorProps}
        onDone={editSensor}
        farmingUnitModal={
          props.current_object instanceof GeneralClasses.FarmingUnit ||
          'region' in props.current_object
        }
        onCancel={CancelEditFieldHandler}
        farmingUnitSensors={props.farmingUnitSensors}
      />
    );
  });

  return (
    <div className='ml-2 text-grey45'>
      <div className='d-flex align-items-center justify-content-between mb-3 mt-5'>
        <div>
          <label className='font-weight-bold text-size-20 mb-0'>
            {t('sensors')}
          </label>
        </div>
        <button
          type='button'
          className='btn btn-primary mr-2 blue-primary align-items-center d-flex justify-content-center'
          onClick={addNewFieldHandler}
          disabled={buttonClicked}
          style={{
            fontSize: 12,
            borderColor: buttonClicked ? '#D0D3D9' : 'initial',
            background: buttonClicked ? '#D0D3D9' : '#4563C7',
            height: '24px',
            width: '80px',
            padding: '3px 0px 3px',
          }}
        >
          <img
            src={addNewIcon}
            style={{ height: '12px', width: '12px' }}
            className='mx-1'
            alt='add new sensor'
          />
          Add new
        </button>
      </div>
      <div>
        {addNewFieldClicked ? (
          <EditSensor
            onDone={addSensor}
            onCancel={addNewFieldHandler}
            farmingUnitModal={
              props.current_object instanceof GeneralClasses.FarmingUnit ||
              'region' in props.current_object
            }
            farmingUnitSensors={props.farmingUnitSensors}
          />
        ) : null}
        {sensorsElements}
      </div>
    </div>
  );
}

module.exports = connect()(withTranslation()(SensorsWrapper));
