import moment from "moment-timezone";
import { getRelevantCyclicDateByDate } from "./helpers";

export const PHASE_TYPES = {
  BY_MONTH: "byMonth",
  BY_GROWTH_PHASE: "byGrowthPhase",
  BY_GROWTH_PHASE_GDD: "byGrowthPhaseGDD",
};

export const transformKCTableDataToDisplayData = (
  KCTable,
  sowDate,
  currentGDD,
) => {
  if (!KCTable || KCTable.type === PHASE_TYPES.BY_MONTH) return null;

  const phasesType = KCTable.type;
  const data =
    phasesType === PHASE_TYPES.BY_GROWTH_PHASE
      ? KCTable.data.byGrowthPhase
      : KCTable.data.byGrowthPhaseGDD;

  const currentMoment = moment();
  const fieldStartDate = getRelevantCyclicDateByDate(
    currentMoment,
    moment(sowDate),
  );
  const daysFromStart = Math.round(
    (currentMoment.unix() - fieldStartDate.unix()) / (60 * 60 * 24),
  );

  const transformPhase = (phase, index, prevPhase) => {
    const total = parseFloat(phase.duration);
    const isDev = phase.is_dev;
    const name = phase.name;
    const displayName =
      prevPhase?.duration === "1" && isDev ? `Dev ${prevPhase.name}` : name;
    const isStressActive = phase.stress?.is_stress_active ?? false;
    return { name, displayName, total, isStressActive, isDev, index };
  };

  const addPhaseBoundaries = (phase, startValue, phasesType) => ({
    ...phase,
    ...calculatePhaseBoundaries(startValue, phase.total, phasesType),
  });

  let phasesData = [];
  let startValue = 0;
  let startDate = moment(fieldStartDate);

  phasesData = data.map((phase, i) => {
    const prevPhase = i > 0 ? data[i - 1] : null;
    const basePhase = transformPhase(phase, i, prevPhase);

    const transformedPhase = addPhaseBoundaries(
      basePhase,
      phasesType === PHASE_TYPES.BY_GROWTH_PHASE ? startDate : startValue,
      phasesType,
    );

    startValue = transformedPhase.end;
    startDate = moment(transformedPhase.end);

    return transformedPhase;
  });

  const totalAccumulatedValue = phasesData.reduce(
    (acc, phase) => acc + phase.total,
    0,
  );
  const valueFromSeasonStart =
    phasesType === PHASE_TYPES.BY_GROWTH_PHASE
      ? daysFromStart
      : Math.round(currentGDD);

  const currentPhaseFunction =
    phasesType === PHASE_TYPES.BY_GROWTH_PHASE
      ? getCurrentPhaseByDays
      : getCurrentPhaseByGDD;

  let estimatedSeasonEnd =
    phasesType === PHASE_TYPES.BY_GROWTH_PHASE
      ? moment(fieldStartDate)
          .add(totalAccumulatedValue, "days")
          .format("DD MMM, YYYY")
      : totalAccumulatedValue + " ";

  return {
    phasesType,
    phasesData,
    fieldStartDate,
    valueFromSeasonStart,
    totalAccumulatedValue,
    endOfSeasonValue: Math.round(totalAccumulatedValue - valueFromSeasonStart),
    nowPosition: (valueFromSeasonStart / totalAccumulatedValue) * 100,
    estimatedSeasonEnd,
    currentPhaseName: currentPhaseFunction(
      currentMoment.unix(),
      moment(sowDate).unix(),
      totalAccumulatedValue,
      valueFromSeasonStart,
      phasesData,
    ),
  };
};

const calculatePhaseBoundaries = (startValue, duration, phasesType) => {
  if (phasesType === PHASE_TYPES.BY_GROWTH_PHASE) {
    const startDate = moment(startValue);
    return {
      start: startDate.format("DD MMM, YYYY"),
      end: startDate.clone().add(duration, "days").format("DD MMM, YYYY"),
    };
  }
  // For GDD type
  return {
    start: startValue,
    end: startValue + duration,
  };
};

const calculatePhaseDuration = (start, end, phasesType) => {
  if (phasesType === PHASE_TYPES.BY_GROWTH_PHASE) {
    return moment(end).diff(start, "days");
  }
  // For GDD type
  return end - start;
};

export const getCurrentPhaseByDays = (
  currentDate,
  sowDate,
  totalDays,
  daysFromSeasonStart,
  phasesData,
) => {
  const current = moment.unix(currentDate);
  const sow = moment.unix(sowDate);
  const seasonEnd = sow.clone().add(totalDays, "days");

  if (current.isBefore(sow)) return "pre-season";
  if (current.isAfter(seasonEnd)) return "between-seasons";

  const currentPhase = phasesData.find((phase) =>
    current.isBetween(moment(phase.start), moment(phase.end), undefined, "[]"),
  );

  return currentPhase?.name || "";
};

export const getCurrentPhaseByGDD = (
  currentDate,
  sowDate,
  totalGDD,
  gddFromSeasonStart,
  phasesData,
) => {
  if (moment.unix(sowDate).isAfter(moment.unix(currentDate)))
    return "pre-season";
  if (gddFromSeasonStart > totalGDD) return "between-seasons";

  const currentPhase = phasesData.find(
    (phase) =>
      gddFromSeasonStart >= phase.start && gddFromSeasonStart < phase.end,
  );

  return currentPhase?.name || "";
};

export const updateKCTablePhase = (
  kcTable,
  viewPhase,
  selectedValue,
  changeType,
) => {
  const phasesType = kcTable.type;
  const data =
    phasesType === PHASE_TYPES.BY_GROWTH_PHASE
      ? kcTable.data.byGrowthPhase
      : kcTable.data.byGrowthPhaseGDD;

  // Find the corresponding phase in KC table data using the view phase's index
  const phase = data[viewPhase.index];

  // Update the phase in the KC table data
  if (changeType === "start") {
    const prevPhase = data[viewPhase.index - 1];
    if (prevPhase) {
      // When start value is changed:
      // - If start is smaller: current phase duration stays same, prev phase duration gets smaller
      // - If start is bigger: only current phase duration gets smaller
      if (phasesType === PHASE_TYPES.BY_GROWTH_PHASE) {
        const currentStartMoment = moment(viewPhase.start);
        const newStartMoment = moment(selectedValue);

        if (newStartMoment.isBefore(currentStartMoment)) {
          // Start value is smaller - adjust previous phase duration
          prevPhase.duration = newStartMoment.diff(
            moment(prevPhase.start),
            "days",
          );
          // Current phase duration stays the same
        } else {
          // Start value is bigger - only adjust current phase duration
          phase.duration = moment(viewPhase.end).diff(newStartMoment, "days");
        }
      } else {
        // For GDD type
        const currentStart = parseFloat(viewPhase.start);
        const newStart = parseFloat(selectedValue);

        if (newStart < currentStart) {
          // Start value is smaller - adjust previous phase duration
          prevPhase.duration = newStart - prevPhase.start;
          // Current phase duration stays the same
        } else {
          // Start value is bigger - only adjust current phase duration
          phase.duration = viewPhase.end - newStart;
        }
      }
    } else {
      // No previous phase - only adjust current phase duration
      phase.duration =
        phasesType === PHASE_TYPES.BY_GROWTH_PHASE
          ? moment(viewPhase.end).diff(moment(selectedValue), "days")
          : viewPhase.end - parseFloat(selectedValue);
    }
  } else if (changeType === "end") {
    // When end value is changed:
    // - If end is smaller: only current phase duration gets smaller
    // - If end is bigger: only current phase duration gets bigger
    phase.duration =
      phasesType === PHASE_TYPES.BY_GROWTH_PHASE
        ? moment(selectedValue).diff(moment(viewPhase.start), "days")
        : parseFloat(selectedValue) - viewPhase.start;
  }

  // Update the KC table data
  kcTable.data[phasesType] = data;

  return kcTable;
};
