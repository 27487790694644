var React = require('react');
var { connect } = require('react-redux');
var { withTranslation, useTranslation } = require('react-i18next');
const { useState, useEffect } = require('react');
var PhasesViewTitle = require('PhasesViewTitle');
const $ = require('jquery');

const PhasesViewContainer = (props) => {
  const [editModeOnHover, setEditModeOnHover] = useState(false);
  const [currentPhase, setCurrentPhase] = useState('');
  const [endOfSeason, setEndOfSeason] = useState(null);
  const [offSeasonDisplay, setOffSeasonDisplay] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    // initialize tooltips
    $(function () {
      $('[data-toggle="tooltip"]').tooltip({ offset: '0,0' });
    });
  }, []);

  useEffect(() => {
    if (props?.phenologicalPhasesData?.valueFromSeasonStart) {
      const isBetweenSeasons =
        props.phenologicalPhasesData?.currentPhaseName === 'between-seasons';
      const isPreSeason =
        props.phenologicalPhasesData?.currentPhaseName === 'pre-season';
      setEndOfSeason(
        `${isBetweenSeasons ? t('KC_phases_season_ended') : isPreSeason ? undefined : t('KC_phases_season_end') + ' ' + props.phenologicalPhasesData?.endOfSeasonValue + ' ' + (props?.phenologicalPhasesData?.phasesType === 'byGrowthPhase' ? t('planning_days') : t('gdd'))}`,
      );
      setOffSeasonDisplay(isBetweenSeasons || isPreSeason);
      let currentPhaseName = isBetweenSeasons
        ? t('KC_phases_title_in_between_seasons')
        : isPreSeason
          ? t('KC_phases_title_pre_season')
          : props.phenologicalPhasesData?.currentPhaseName;
      setCurrentPhase(currentPhaseName);
    }
  }, [props.phenologicalPhasesData?.valueFromSeasonStart]);

  const Timeline = ({ phases, nowPosition, offSeasonDisplay, KcTableType }) => {
    const [hoverTimeouts, setHoverTimeouts] = useState({});

    if (!phases) return null;

    const handleMouseHover = (phaseTooltipID, phase) => {
      $(`#${phaseTooltipID}`).tooltip('show');
      // Start a timeout when tooltip is hovered
      const timeout = setTimeout(() => {
        mixpanel.track('Phenological Phase Timeline Review', {
          'Phenological Phase Name': phase.name,
        });
      }, 3000);

      setHoverTimeouts((prev) => ({ ...prev, [phaseTooltipID]: timeout }));
    };

    const handleMouseLeave = (phaseTooltipID) => {
      // Clear timeout if tooltip is closed before 3 seconds
      if (hoverTimeouts[phaseTooltipID]) {
        clearTimeout(hoverTimeouts[phaseTooltipID]);
        setHoverTimeouts((prev) => {
          const newTimeouts = { ...prev };
          delete newTimeouts[phaseTooltipID];
          return newTimeouts;
        });
      }
    };
    const maxTotal = Math.max(...phases?.map((stage) => stage.total));
    return (
      <div className='timeline-container'>
        {phases
          ?.filter((phase) => phase.total > 1)
          ?.map((phase, index) => {
            const isGDDType = KcTableType === 'byGrowthPhaseGDD';
            const phaseFlex = phase.total / maxTotal + 0.5;
            const shouldDisplayStressIndicator = phase?.isStressActive;
            const phaseTooltipID =
              'tooltipID_' +
              String(phase.displayName).replaceAll(' ', '_') +
              '_' +
              index;
            const phaseContainerTooltipID =
              'container_tooltipID_' +
              String(phase.displayName).replaceAll(' ', '_') +
              '_' +
              index;
            const tooltipTitle =
              `<b>${phase.displayName}</b>` +
              '<br />' +
              `${shouldDisplayStressIndicator ? `<b>(${t('KC_phases_stress_inidication')})</b><br />` : ''}` +
              `${t('KC_phases_phase_tooltip_start')}: ${phase.start} ${isGDDType ? 'GDD' : ''}` +
              '<br />' +
              `${t('KC_phases_phase_tooltip_end')}: ${phase.end} ${isGDDType ? 'GDD' : ''}`;
            return (
              <div style={{ flex: phaseFlex }} id={phaseContainerTooltipID}>
                <div
                  id={phaseTooltipID}
                  key={index}
                  className={offSeasonDisplay ? 'offSeasonStage' : 'stage'}
                  data-toggle='tooltip'
                  data-html='true'
                  data-container={`#${phaseContainerTooltipID}`}
                  onMouseOver={() => {
                    handleMouseHover(phaseTooltipID, phase);
                  }}
                  onMouseLeave={() => {
                    handleMouseLeave(phaseTooltipID);
                  }}
                  data-placement='top'
                  title={tooltipTitle}
                  data-offset='0,12'
                >
                  <span className='stage-label'>
                    {phase.displayName}
                    {shouldDisplayStressIndicator && (
                      <img
                        alt=''
                        className='stage-icon'
                        src={require('images/icons/stress_indicator_icon.svg')}
                      />
                    )}
                  </span>
                </div>
              </div>
            );
          })}
        {/* Now Indicator */}
        {!offSeasonDisplay && (
          <div
            className='now-indicator'
            style={{ left: `calc(${nowPosition}% + 40px)` }}
          >
            <div className='now-marker'></div>
            <div className='now-label text-style-titles-12-semi-bold text-grey45'>
              {t('KC_phases_now_indication')}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      onMouseEnter={() => {
        if (!offSeasonDisplay) {
          setEditModeOnHover(true);
        }
      }}
      onMouseLeave={() => {
        if (!offSeasonDisplay) {
          setEditModeOnHover(false);
        }
      }}
      className='d-flex editModeBg'
      style={{
        paddingLeft: '24px',
        paddingRight: '24px',
        borderLeft: '1px solid #DCE0E8',
        borderBottom: '1px solid #DCE0E8',
        borderRight: '1px solid #DCE0E8',
        minHeight: '72px',
      }}
    >
      <PhasesViewTitle
        currentPhase={currentPhase}
        OnEditModePressed={props.OnEditModePressed}
        endOfSeason={endOfSeason}
        shouldDisplayEditMode={editModeOnHover}
      />
      <Timeline
        nowPosition={props?.phenologicalPhasesData?.nowPosition}
        phases={props?.phenologicalPhasesData?.phasesData}
        offSeasonDisplay={offSeasonDisplay}
        KcTableType={props?.phenologicalPhasesData?.phasesType}
      />
    </div>
  );
};

module.exports = connect()(withTranslation()(PhasesViewContainer));
