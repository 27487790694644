var React = require('react');
const { withTranslation } = require('react-i18next');
const { useState } = require('react');

const FieldDetailsMetricBox = ({
  label,
  value,
  unit,
  fieldState,
  lastSeen,
  onHover,
  onHoverLabel,
  hoverCondition,
  appearWithEmptyValue,
  t,
}) => {
  const [isHover, setIsHover] = useState(false);

  if (
    !appearWithEmptyValue &&
    (value === '' || value === null || value === undefined)
  )
    return null;

  let spanStyle =
    fieldState === 'active'
      ? 'text-blue-secondary bg-grey96 font-weight-bold '
      : 'text-grey71 font-weight-bold';
  let paddingStyle = fieldState === 'active' ? 'pl-4 pr-4' : 'pl-5 pr-5';

  let lastSeenStyle = 'text-blue-secondary font-weight-normal text-size-12';
  let divsStyle =
    fieldState === 'active'
      ? 'text-grey45 text-truncate text-size-14'
      : 'text-grey71 text-truncate text-size-14';

  return (
    <div
      className={`text-center flex-grow-1 ${paddingStyle}`}
      style={{
        borderLeft: '0.5px solid #DCE0E8',
        borderBottom: '0.5px solid #DCE0E8',
      }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div className={divsStyle}>
        {hoverCondition && isHover ? t(onHoverLabel) : t(label)}{' '}
        <span
          className={`p-1 pr-2 pl-2 ml-1 ${spanStyle}`}
          style={{ borderRadius: '5px' }}
        >
          {hoverCondition && isHover ? onHover : value} {unit}
          {lastSeen && (
            <span className={lastSeenStyle}>
              {' '}
              {' (' + t(lastSeen[1]).format(lastSeen[0]) + ')'}
            </span>
          )}
        </span>
      </div>
    </div>
  );
};

module.exports = withTranslation()(FieldDetailsMetricBox);
