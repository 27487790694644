const React = require('react');
const { connect } = require('react-redux');
const phasesManager = require('phasesManager');
const distributionActions = require('distributionActions');
const { useState, useEffect, useRef } = require('react');
const PhasesViewContainer = require('PhasesViewContainer');
const PhasesEditContainer = require('PhasesEditContainer');
const { latestObjectfromDict } = require('helpers');
const phasesCSS = require('phasesCSS');
const $ = require('jquery');

const PhasesContainer = (props) => {
  const [phenologicalPhasesData, setPhenologicalPhasesData] = useState(null);
  const [shouldDisplayEditMode, setShouldDisplayEditMode] = useState(false);
  const editContainerRef = useRef(null); // Ref to track the PhasesEditContainer DOM element

  // Reset settings when selectedField changes
  useEffect(
    function resetSettingsWhenChangeField() {
      setShouldDisplayEditMode(false);
      setPhenologicalPhasesData(null);
    },
    [props.selectedField],
  );

  // Load phases data for byGrowthPhase
  useEffect(() => {
    if (
      props.selectedField &&
      props.selectedField.crop_data &&
      props.selectedField.crop_data.kc_table &&
      props.selectedField.crop_data.kc_table.type === 'byGrowthPhase'
    ) {
      let data = phasesManager.transformKCTableDataToDisplayData(
        props.selectedField.crop_data.kc_table,
        props.selectedField.crop_data?.sow_date,
      );
      setPhenologicalPhasesData(data);
    }
  }, [props?.selectedField?.crop_data]);

  // Load phases data for byGrowthPhaseGDD
  useEffect(() => {
    if (
      props?.selectedField?.historical_data?.accu_gdd &&
      props?.selectedField?.historical_data?.accu_gdd?.values &&
      props.selectedField.crop_data.kc_table.type === 'byGrowthPhaseGDD'
    ) {
      let currentGDD = latestObjectfromDict(
        props?.selectedField?.historical_data?.accu_gdd?.values,
      );
      let data = phasesManager.transformKCTableDataToDisplayData(
        props.selectedField.crop_data.kc_table,
        props.selectedField.crop_data?.sow_date,
        currentGDD,
      );
      setPhenologicalPhasesData(data);
    }
  }, [props?.selectedField?.historical_data?.accu_gdd]);

  // Exit edit mode on successful field update
  useEffect(() => {
    if (
      props.field_actions.status === 'success' &&
      props.field_actions.type === 'UPDATE'
    ) {
      setShouldDisplayEditMode(false);
    }
  }, [props.field_actions?.status, props.field_actions?.type]);

  // Handle click outside to close edit mode
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        shouldDisplayEditMode &&
        editContainerRef.current &&
        !editContainerRef.current.contains(event.target)
      ) {
        setShouldDisplayEditMode(false);
      }
    };

    // Add event listener when edit mode is active
    if (shouldDisplayEditMode) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // Cleanup event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [shouldDisplayEditMode]); // Dependency on edit mode state

  const savePhasesData = (data) => {
    let updatedFieldObj = {
      ...props.selectedField,
      crop_data: { ...props.selectedField.crop_data, kc_table: data },
    };
    props.update_field(
      props.selectedDistribution.id,
      props.selectedFarmingUnit.id,
      updatedFieldObj,
    );
    mixpanel.track('Phenological Phase Change Saved');
  };

  const handlePressedOnEditMode = () => {
    setShouldDisplayEditMode(true);
    mixpanel.track('Phenological Phase Edit Mode On');
  };

  return (
    <div>
      {phenologicalPhasesData &&
        (shouldDisplayEditMode ? (
          <div ref={editContainerRef}>
            <PhasesEditContainer
              phenologicalPhasesData={phenologicalPhasesData}
              onCancelPressed={() => setShouldDisplayEditMode(false)}
              onSavePressed={savePhasesData}
            />
          </div>
        ) : (
          <PhasesViewContainer
            phenologicalPhasesData={phenologicalPhasesData}
            OnEditModePressed={handlePressedOnEditMode}
            shouldDisplayEditMode={shouldDisplayEditMode}
          />
        ))}
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    selectedField: state.distribution_data.selected_entities.field,
    loadHistoryStatus: state.distribution_data.field_load_history,
    selectedFarmingUnit: state.distribution_data.selected_entities.farming_unit,
    selectedDistribution:
      state.distribution_data.selected_entities.distribution,
    field_actions: state.distribution_data.field_actions,
  };
};

const mapDispatchToProps = {
  update_field: distributionActions.update_field,
};

module.exports = connect(mapStateToProps, mapDispatchToProps)(PhasesContainer);
