var React = require('react');
var { useCallback, useEffect, useMemo } = require('react');
var { connect } = require('react-redux');
var { withTranslation, useTranslation } = require('react-i18next');
const { useState } = require('react');
const { ResponsiveBar } = require('@nivo/bar');
const DayPickerInput = require('../field/DayPickerInput');
const moment = require('moment');
const phasesManager = require('phasesManager');
const { useDebounce } = require('hooks');
const stressIndicatorIcon = require('images/icons/stress_indicator_icon.svg');
const { latestObjectfromDict } = require('helpers');
const { PHASE_TYPES } = phasesManager;

const PhasesEditContainer = (props) => {
  const [phenologicalPhasesDataCopy, setPhenologicalPhasesDataCopy] = useState(
    JSON.parse(JSON.stringify(props.phenologicalPhasesData)),
  );
  const [kcTableData, setKcTableData] = useState(
    JSON.parse(JSON.stringify(props.selectedField.crop_data.kc_table)),
  );
  const [selectedPhase, setSelectedPhase] = useState(null);
  const [displayError, setDisplayError] = useState(false);
  const { t } = useTranslation();
  const isDaysDisplay =
    phenologicalPhasesDataCopy.phasesType === PHASE_TYPES.BY_GROWTH_PHASE;
  let isSaveButtonPressed =
    props.field_actions.type === 'UPDATE' &&
    props.field_actions.status === 'init';
  let estimatedSeasonEndDisplay = isDaysDisplay
    ? phenologicalPhasesDataCopy.estimatedSeasonEnd
    : phenologicalPhasesDataCopy.estimatedSeasonEnd + t('gdd');
  const handleChangePhase = useCallback((phase, selectedValue, changeType) => {
    const updatedKcTable = phasesManager.updateKCTablePhase(
      kcTableData,
      phase,
      selectedValue,
      changeType,
    );
    const newDisplayData = phasesManager.transformKCTableDataToDisplayData(
      updatedKcTable,
      props.selectedField.crop_data?.sow_date,
      props.selectedField?.historical_data?.accu_gdd?.values
        ? latestObjectfromDict(
            props.selectedField.historical_data.accu_gdd.values,
          )
        : undefined,
    );

    setKcTableData(updatedKcTable);
    setPhenologicalPhasesDataCopy(newDisplayData);
    setSelectedPhase(newDisplayData.phasesData[phase.index]);

    mixpanel.track('Phenological Phase Advanced Flow Changed', {
      'Advanced Flow Input Changed': `new ${changeType} date - ${selectedValue} in phase ${phase.name}`,
    });
  }, []);

  const handleSelectBar = useCallback((barId) => {
    let pressedPhase = phenologicalPhasesDataCopy.phasesData.find(
      (phase) => phase.index === parseInt(barId),
    );
    setSelectedPhase(pressedPhase);
    mixpanel.track('Phenological Phase Advanced Flow Phase Clicked', {
      'Phase Clicked': pressedPhase.name,
    });
  }, []);

  const handleResetPressed = () => {
    setSelectedPhase(null);
    setPhenologicalPhasesDataCopy(props.phenologicalPhasesData);
    setKcTableData(props.selectedField.crop_data.kc_table);
    mixpanel.track('Phenological Phase Reset Button Clicked');
  };

  const handleSavePressed = () => {
    props.onSavePressed(kcTableData);
  };

  const ManualModeComponent = useCallback(
    ({ phase, isDaysDisplay }) => {
      const [startDate, setStartDate] = useState(
        phase?.start === 0 ? '0' : phase?.start || '',
      );
      const [endDate, setEndDate] = useState(phase?.end || '');
      const [startDateError, setStartDateError] = useState(false);
      const [endDateError, setEndDateError] = useState(false);

      const isStartValueValid = useCallback((value, phase) => {
        if (phase.index === 0) return true;
        let previousPhase = phenologicalPhasesDataCopy.phasesData.find(
          (p) => phase.index === p.index + 1,
        );
        let isValueValid = isDaysDisplay
          ? (!previousPhase || isEndValueValid(value, previousPhase)) &&
            moment(value).isBefore(phase.end)
          : (!previousPhase ||
              isEndValueValid(parseFloat(value), previousPhase)) &&
            value < phase.end;
        return isValueValid;
      }, []);

      const isEndValueValid = useCallback((value, phase) => {
        return isDaysDisplay
          ? moment(value).isSameOrAfter(moment(phase.start).add(1, 'days'))
          : phase.start < parseFloat(value);
      }, []);

      // Apply debounce only for number inputs
      const debouncedStartValue = useDebounce(startDate, 500);
      const debouncedEndValue = useDebounce(endDate, 500);

      useEffect(() => {
        if (
          !isDaysDisplay &&
          phase &&
          debouncedStartValue !== phase.start &&
          phase.index !== 0
        ) {
          if (isStartValueValid(debouncedStartValue, phase)) {
            handleChangePhase(phase, debouncedStartValue, 'start');
            setStartDateError(false);
            setDisplayError(false);
          } else {
            setStartDateError(true);
            setDisplayError(true);
          }
        }
      }, [debouncedStartValue, phase, isDaysDisplay]);

      useEffect(() => {
        if (!isDaysDisplay && phase && debouncedEndValue !== phase.end) {
          if (isEndValueValid(debouncedEndValue, phase)) {
            handleChangePhase(phase, debouncedEndValue, 'end');
            setEndDateError(false);
            setDisplayError(false);
          } else {
            setEndDateError(true);
            setDisplayError(true);
          }
        }
      }, [debouncedEndValue, phase, isDaysDisplay]);

      const isFirstPhase = phase?.index === 0;
      const isDisabled = !phase;

      // Function to render input field dynamically
      const renderInputField = (value, setValue, label, type) => (
        <div
          className='d-flex'
          style={{ flexDirection: 'column', width: '50%', gap: '4px' }}
        >
          <div className='text-style-titles-14-semi-bold text-grey45'>
            {t(label)}
          </div>
          {isDaysDisplay ? (
            <DayPickerInput
              inputProps={{
                placeholder: 'Select...',
                disabled: isDisabled || (type === 'start' && isFirstPhase),
              }}
              value={phase ? moment(value).format('DD MMM, YYYY') : ''}
              onChange={(e) => {
                const formattedDate = e.format('DD MMM, YYYY');
                setValue(formattedDate);
                handleChangePhase(phase, formattedDate, type);
              }}
              isValidDate={(selectedDate) =>
                phase &&
                (type === 'start'
                  ? isStartValueValid(selectedDate, phase)
                  : isEndValueValid(selectedDate, phase))
              }
              dateFormat='DD MMM, YYYY'
            />
          ) : (
            <input
              disabled={isDisabled || (type == 'start' && isFirstPhase)}
              className={`text-grey45 form-control placeholder ${(type === 'start' && startDateError) || (type === 'end' && endDateError) ? 'border-alert' : ''}`}
              placeholder={'Select'}
              type='number'
              value={value || ''}
              onChange={(e) => setValue(e.target.value)}
            />
          )}
        </div>
      );

      return (
        <div
          className='d-flex justify-content-center align-items-center'
          style={{
            paddingTop: '24px',
            paddingBottom: '20px',
            flexDirection: 'column',
            width: '300px',
          }}
        >
          <div
            className='text-style-titles-16-bold text-grey45'
            style={{ paddingBottom: '16px' }}
          >
            {phase
              ? `${t('KC_phases_button_edit')} ${phase.displayName || ''} ${t('phase')} ${t('duration')}`
              : t('KC_phases_edit_mode_default_title')}
          </div>

          <div
            className='d-flex justify-content-center align-items-center'
            style={{ flexDirection: 'row', width: '100%', gap: '8px' }}
          >
            {renderInputField(
              startDate,
              setStartDate,
              'KC_phases_phase_tooltip_start',
              'start',
            )}
            {renderInputField(
              endDate,
              setEndDate,
              'KC_phases_phase_tooltip_end',
              'end',
            )}
          </div>
          <div style={{ height: '0px' }}>
            {(startDateError || endDateError) && (
              <div
                className='text-style-body-14-semi-bold'
                style={{ color: '#F66B7A' }}
              >
                {t('kc_phases_error_message')}
              </div>
            )}
          </div>
        </div>
      );
    },
    [selectedPhase, isDaysDisplay],
  );

  const EditPhasesTimeline = useCallback(
    ({ phases, setSelectedBar }) => {
      const generateTooltip = (barData) => {
        let hoveredBar = phases.phasesData.find(
          (phase) => phase.index === parseInt(barData.id),
        );
        let shouldDisplayStress = hoveredBar.isStressActive;
        return (
          <div className='tooltipBody'>
            <span className='text-style-body-14-bold'>
              {hoveredBar.displayName}
              <br />
            </span>
            {shouldDisplayStress && (
              <span className='text-style-body-14-bold'>
                ({t('KC_phases_stress_inidication')})<br />
              </span>
            )}
            <span className='text-style-body-14-regular'>
              {t('start')}: {hoveredBar.start}
            </span>
            <br />
            <span className='text-style-body-14-regular'>
              {t('end')}: {hoveredBar.end}
            </span>

            {/* Triangle (CSS Arrow) */}
            <div className='tooltipFooter' />
          </div>
        );
      };
      const generateLabels = (bars) => {
        return (
          <g>
            {bars.map(({ width, y, x, data, theme }) => {
              if (width <= 12) return null;
              // Create a temporary canvas to measure text width
              const canvas = document.createElement('canvas');
              const context = canvas.getContext('2d');
              context.font = `bold 12px ${theme?.labels?.text?.fontFamily || 'sans-serif'}`; // Match the text style
              // Get the actual phase name
              const phase = phases.phasesData.find(
                (phase) => phase.index === parseInt(data.id),
              ) || { name: '' };
              // Measure the actual text width of phaseName
              const textWidth = context.measureText(phase.displayName).width;
              // Determine what to display based on bar width
              let displayedText = '...';
              if (width > textWidth) {
                displayedText = phase.displayName;
              } else if (width > context.measureText('...').width) {
                // Trim text if it's too long for the bar
                let maxTextWidth = width - context.measureText('...').width;
                for (let i = phase.displayName.length; i > 0; i--) {
                  const substring = phase.displayName.slice(0, i);
                  if (context.measureText(substring).width <= maxTextWidth) {
                    displayedText = substring + '...';
                    break;
                  }
                }
              }
              let shouldDisplayStress =
                phase.isStressActive && !displayedText.includes('...');
              // Define image dimensions and position
              const imageWidth = 14; // Adjust based on your icon size
              const imageHeight = 14; // Adjust based on your icon size
              const textX = x;
              const textY = y - 9;
              const imageX =
                textX + context.measureText(displayedText).width + 2; // Position after text with a small gap
              const imageY = textY - imageHeight / 2; // Center vertically relative to text

              return (
                <g key={data.id}>
                  <text
                    x={x}
                    y={y - 5}
                    textAnchor='start'
                    style={{
                      fill: '#4D5674',
                      fontSize: '12px',
                      fontWeight: 'bold',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {displayedText}
                  </text>
                  {shouldDisplayStress && (
                    <image
                      x={imageX}
                      y={imageY}
                      width={imageWidth}
                      height={imageHeight}
                      href={stressIndicatorIcon} // Use href for SVG image
                    />
                  )}
                  ); })}
                </g>
              );
            })}
          </g>
        );
      };

      const generateNowIndicator = (xScale, height) => {
        let nowPosition = xScale(phases.valueFromSeasonStart);
        return (
          <>
            <line
              x1={nowPosition}
              x2={nowPosition}
              y1={0}
              y2={height - 30}
              stroke='#E55565'
              strokeWidth={2}
            />
            <foreignObject
              x={nowPosition - 20}
              y={height - 40}
              width='40'
              height='100%'
              overflow={'visible'}
            >
              <div className='now-label text-style-titles-12-semi-bold text-grey45'>
                {t('KC_phases_now_indication')}
              </div>
            </foreignObject>
          </>
        );
      };

      const onBarClick = (bar, mouseEvent) => {
        setSelectedBar(bar.id);
        setSelectedBarID(bar.id);
        document.querySelectorAll('rect').forEach((graphBar) => {
          if (graphBar.ariaLabel !== null) {
            graphBar.setAttribute('stroke', '');
            graphBar.setAttribute('stroke-width', '0');
            graphBar.setAttribute('fill', '#0ACBBC');
          } else if (graphBar.ariaLabel === mouseEvent.id) {
            graphBar.setAttribute('fill', '#0ACBBC');
            graphBar.setAttribute('stroke', '#4D5674');
            graphBar.setAttribute('stroke-width', '2');
          }
        });
        mouseEvent.target.attributes.stroke.value = '#4D5674';
        mouseEvent.target.attributes['stroke-width'].value = '2';
      };
      const onBarHover = (bar, mouseEvent) => {
        mouseEvent.target.attributes.stroke.value = '#007A6A';
        mouseEvent.target.attributes['stroke-width'].value = '2';
        mouseEvent.target.attributes.fill.value = '#00B2A5';
      };
      const onBarLeave = (bar, mouseEvent) => {
        if (selectedBarID !== bar.id) {
          mouseEvent.target.attributes.stroke.value = '';
          mouseEvent.target.attributes['stroke-width'].value = '0';
          mouseEvent.target.attributes.fill.value = '#0ACBBC';
        } else {
          mouseEvent.target.attributes.stroke.value = '#4D5674';
          mouseEvent.target.attributes['stroke-width'].value = '2';
          mouseEvent.target.attributes.fill.value = '#0ACBBC';
        }
      };

      const xAxisFormatter = (xValue) => {
        if (isDaysDisplay) {
          let seasonStartValue = phases.valueFromSeasonStart;
          const resultDate = new Date(); // Clone the start date
          resultDate.setDate(resultDate.getDate() - seasonStartValue + xValue);
          return resultDate.toLocaleDateString('en-US', {
            month: 'short',
            day: '2-digit',
          });
        }
      };
      const transformedData = [
        phases?.phasesData.reduce(
          (acc, phase) => ({
            ...acc,
            [phase.index]: phase.total,
          }),
          { name: 'Phases' },
        ),
      ];

      const keys = Object.keys(transformedData[0]).filter(
        (key) => key !== 'name',
      );

      const [selectedBarID, setSelectedBarID] = useState(
        selectedPhase?.index || null,
      );

      if (!transformedData) return null;
      else
        return (
          <div style={{ display: 'inline', width: '100%', height: '400px' }}>
            <ResponsiveBar
              data={transformedData}
              keys={keys}
              innerPadding={2}
              indexBy='name'
              colors={['#0ACBBC']}
              borderRadius={4}
              label={null}
              margin={{ right: 30, bottom: 30, left: 30 }}
              theme={{
                axis: {
                  domain: { line: { stroke: '#DCE0E8' } },
                  ticks: {
                    text: {
                      fill: '#919DB4',
                      fontWeight: '600',
                      fontSize: 12,
                      lineHeight: 12,
                      fontFamily: 'Source Sans Pro, sans-serif',
                    },
                  },
                },
                grid: {
                  line: { stroke: '#DCE0E8', strokeWidth: 1 },
                },
              }}
              layout='horizontal'
              valueScale={{
                type: 'linear',
              }}
              initialHiddenIds={['name']}
              indexScale={{ type: 'band', round: true }}
              padding={0.25}
              enableGridX={true}
              enableGridY={false}
              axisTop={null}
              axisRight={null}
              axisLeft={null}
              role='application'
              renderWrapper='svg'
              animate={false}
              barAriaLabel={(data) => `${data.id}`}
              axisBottom={{
                format: (value) => xAxisFormatter(value),
                tickSize: 0,
                tickPadding: 10,
                legend: '',
                legendPosition: 'middle',
                legendOffset: 40,
              }}
              onMouseEnter={(bar, event) => onBarHover(bar, event)}
              onMouseLeave={(bar, event) => onBarLeave(bar, event)}
              onClick={(bar, event) => onBarClick(bar, event)}
              tooltip={(barData) => generateTooltip(barData)}
              layers={[
                'grid',
                'bars',
                'axes',
                'markers',
                'legends',
                ({ bars }) => generateLabels(bars),
                ({ xScale, width, height }) =>
                  generateNowIndicator(xScale, height),
              ]}
            />
          </div>
        );
    },
    [phenologicalPhasesDataCopy],
  );

  return (
    <div
      className='d-flex bg-white'
      style={{
        height: '416px',
        border: '1px solid #DCE0E8',
        borderTop: '0px',
        padding: '8px',
        paddingBottom: '16px',
      }}
    >
      <div
        className='bg-white w-100'
        style={{
          borderRadius: '8px',
          border: '1px solid #DCE0E8',
          boxShadow: '0px 3px 6px 0px #1C1D204D',
        }}
      >
        <div
          className='d-flex justify-content-start align-items-center'
          style={{
            height: '336px',
            borderBottom: '1px solid #DCE0E8',
            flexDirection: 'column',
          }}
        >
          <ManualModeComponent
            phase={selectedPhase}
            isDaysDisplay={isDaysDisplay}
          />
          <div
            className='bg-grey96'
            style={{
              position: 'relative',
              width: '95%',
              height: '156px',
              border: '1px solid #DCE0E8',
              borderRadius: '8px',
              marginLeft: '24px',
              marginRight: '24px',
            }}
          >
            <EditPhasesTimeline
              phases={phenologicalPhasesDataCopy}
              setSelectedBar={handleSelectBar}
            />
            <div
              className='text-grey71 text-style-titles-12-regular w-100'
              style={{
                display: 'flex',
                justifyContent: 'end',
                paddingTop: '8px',
                paddingBottom: '24px',
              }}
            >
              {`${t('KC_phases_estimated_season_end')}: ${estimatedSeasonEndDisplay}`}
            </div>
          </div>
        </div>

        <div
          className='d-flex align-items-center'
          style={{ padding: ' 16px 24px', height: '56px' }}
        >
          <div
            className='d-flex justify-content-center align-items-center'
            onClick={handleResetPressed}
            style={{
              paddingLeft: '8px',
              paddingRight: '12px',
              gap: '2px',
              cursor: 'pointer',
            }}
          >
            <img src={require('images/icons/reset_icon.svg')} />
            <div className='text-grey45 text-style-titles-14-semi-bold'>
              {t('KC_phases_button_reset')}
            </div>
          </div>
          <div
            className='d-flex ml-auto justify-content-center align-items-center'
            style={{ gap: '8px' }}
          >
            <button
              className='d-flex justify-content-center align-items-center btn text-grey45 secondary-button text-style-titles-14-semi-bold footerButton'
              disabled={isSaveButtonPressed}
              onClick={() => {
                props.onCancelPressed(false);
              }}
            >
              {t('cancel')}
            </button>
            <button
              onClick={handleSavePressed}
              disabled={isSaveButtonPressed || displayError}
              className='d-flex justify-content-center align-items-center btn text-white primary-button text-style-titles-14-semi-bold footerButton'
            >
              {isSaveButtonPressed ? (
                <span
                  className='spinner-border spinner-border-sm'
                  role='status'
                  aria-hidden='true'
                ></span>
              ) : (
                t('save')
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    selectedField: state.distribution_data.selected_entities.field,
    field_actions: state.distribution_data.field_actions,
  };
};

module.exports = connect(mapStateToProps)(
  withTranslation()(PhasesEditContainer),
);
